<template>
  <v-container class="pa-0">
    <p class="home-section-title my-4">
      Supporting all kinds of labs
    </p>
    <v-layout wrap row>
      <v-flex
        xs12
        sm6
        lg3
        class="pb-4 pr-4"
        v-for="entry in cards"
        :key="entry.name"
      >
        <v-card>
          <v-layout class="blue-grey darken-3" justify-center>
            <v-img
              :src="entry.image"
              max-width="100px"
              class="stock-image my-4"
            ></v-img>
          </v-layout>
          <v-card-title class="mt-2">{{ entry.name }} </v-card-title>
          <v-card-text>
            {{ entry.description }}
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import ClinicsImg from "@bugseq-site/www/src/assets/img/home/clinics.png";
import PublicHealthLabsImg from "@bugseq-site/www/src/assets/img/home/public-health-labs.png";
import MilitaryImg from "@bugseq-site/www/src/assets/img/home/military.png";
import ResearchImg from "@bugseq-site/www/src/assets/img/home/research.png";

export default {
  data() {
    return {
      cards: [
        {
          name: "Clinical",
          image: ClinicsImg,
          description:
            "Identify pathogens and their antimicrobial susceptibilities direct from specimen.",
        },
        {
          name: "Public Health",
          image: PublicHealthLabsImg,
          description:
            "Track the spread of pathogens through populations with phylogenetics.",
        },
        {
          name: "Defense",
          image: MilitaryImg,
          description:
            "Identify known and novel agents of bioterrorism with a pathogen agnostic approach.",
        },
        {
          name: "Research",
          image: ResearchImg,
          description:
            "Detect contaminating microbes and the toxins they produce.",
        },
      ],
    };
  },
};
</script>

<style scoped>
.v-card {
  height: 100%;
}

.stock-image {
  filter: invert(100%);
}
</style>
