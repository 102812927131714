<template>
  <v-layout row wrap justify-center align-stretch class="mt-4">
    <v-flex xs12 sm9 md4 v-for="tier in tiers" :key="tier.title" class="mb-4">
      <v-card class="mx-3 full-height">
        <v-layout column justify-space-between class="full-height">
          <div class="border-top" :class="tier.borderClass">
            <div class="title-subtitle mb-3">
              <v-card-title class="mt-2 mb-2">
                {{ tier.title }}
              </v-card-title>
              <v-card-text
                class="font-weight-regular pt-0 grey--text text--darken-2"
              >
                {{ tier.subtext }}
              </v-card-text>
            </div>
            <v-layout
              column
              justify-center
              class="pricing-section grey lighten-4"
            >
              <v-icon medium :class="tier.pricingLink && 'primary--text'">{{
                tier.pricingIcon
              }}</v-icon>
              <span v-if="!tier.pricingLink" class="non-link-pricing-text">
                {{ tier.pricingText }}
              </span>
              <v-btn
                :href="tier.pricingLink"
                v-else
                color="primary"
                flat
                class="text-none pricing-link"
              >
                <b>{{ tier.pricingText }}</b>
                <v-icon>arrow_right_alt</v-icon>
              </v-btn>
            </v-layout>
            <v-card-text class="mt-4">
              Included:
              <ul>
                <li
                  v-for="feature in tier.features"
                  :key="feature"
                  v-html="feature"
                />
              </ul>
            </v-card-text>
          </div>
          <v-btn
            outline
            class="get-started my-4 mx-3 text-none"
            :href="tier.callToActionLink"
            @click="tier.callToActionMethod()"
            >{{ tier.callToAction }}</v-btn
          >
        </v-layout>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script lang="ts">
export default {
  data() {
    return {
      tiers: [
        {
          title: "Academic",
          borderClass: "border-top-teal",
          subtext:
            "Enabling cutting-edge research with research-friendly pricing.",
          pricingIcon: "manage_search",
          pricingText: "Research with Ease",
          features: [
            "Up to 10 samples per week",
            "Unlimited data retention",
            "DNA/RNA/cDNA analysis",
            "Leading accuracy analysis with the latest BugSeq version",
            "Choice of database",
            "24 hour turnaround time",
          ],
          callToAction: "Get in Touch",
          callToActionLink: "/quote",
        },
        {
          title: "Lab",
          borderClass: "border-top-blue",
          subtext: "Offload your whole lab's analyses to BugSeq.",
          pricingIcon: "auto_graph",
          pricingText: "Supercharge your Lab",
          pricingLink: "/quote",
          features: [
            "Unlimited data submission",
            "Unlimited data retention",
            "DNA/RNA/cDNA Analysis",
            "Version controlled analysis",
            "Choice of database",
            "Priority bioinformatics support",
            "Data locality and compliance",
            "2 hour turnaround time",
          ],
          callToAction: "Get in Touch",
          callToActionLink: "/quote",
        },
      ],
    };
  },
};
</script>

<style scoped>
.full-height {
  height: 100%;
}

.title-subtitle {
  height: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pricing-section {
  height: 140px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.pricing-link {
  font-size: inherit;
}

.non-link-pricing-text {
  padding-top: 10px;
  padding-bottom: 10px;
}

.border-top {
  border-top: 6px solid;
}

.border-top-teal {
  border-color: #00897b; /* teal darken-1 */
}

.border-top-blue {
  border-color: #1976d2; /* blue darken-2 */
}
</style>
