<template>
  <v-app class="white google-font">
    <v-container fluid class="grey lighten-4 pa-0">
      <v-layout column>
        <v-flex class="blue darken-2 py-2">
          <v-layout justify-center>
            <a v-on:click="redirectToSignUp()" class="white--text subheading banner-text my-1">
              <v-layout>
                <b>Free Trial<span class="mx-2">|</span></b>Try out BugSeq on your own data for free 
                <v-icon dark class="ml-1">keyboard_double_arrow_right</v-icon>
              </v-layout>
            </a>
          </v-layout>
        </v-flex>
        <v-flex>
          <v-img
            :src="HeroImg"
            gradient="to right, #0D47A1, rgba(0, 0, 0, 0)"
            max-height="350px"
          >
            <v-layout fill-height justify-center>
              <v-flex class="content-max-width">
                <v-layout fill-height align-center>
                  <v-flex xs12 md5 class="mx-5 blue-grey darken-4 white--text hero-text-box">
                    <div class="display-1 pa-4">BugSeq's Solutions</div>
                    <div class="pl-4">Complete end-to-end analysis for any experimental design. No infrastructure required.</div>
                    <v-btn class="ma-4 text-capitalize" v-on:click="redirectToSignUp()">Get Started on BugSeq</v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-img>
        </v-flex>
        <v-flex class="px-5 py-4">
          <v-layout justify-center>
            <v-flex class="content-max-width">
              <div class="title mb-3">Fueling labs with actionable insights from NGS</div>
              <div>From identifying pathogens, to predicting antimicrobial resistance, to characterizing outbreaks, BugSeq makes it easier to access the analysis, databases, validation and secure infrastructure needed to scale sequencing across your organization. Learn how BugSeq can accelerate bioinformatics and improve your microbiology service.</div>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex class="px-5 py-4">
          <v-layout justify-center>
            <v-flex class="content-max-width">
              <v-layout wrap>
                <v-flex
                  xs12
                  sm6
                  md4
                  lg3
                  class="px-2 my-2"
                  v-for="section in sections"
                  :key="section.anchor"
                >
                  <div class="blue-grey darken-4 white--text pa-3 border-radius">
                    <v-layout column>
                      <v-flex xs12 class="pa-4" align-self-center>
                        <v-img
                          :src="section.image"
                          width="100px"
                          height="100px"
                          class="stock-image border-radius"
                        >
                        </v-img>
                      </v-flex>
                      <v-flex xs12 align-self-center>
                        <v-btn
                          flat
                          outline
                          color="white"
                          class="mx-0 mb-3 text-capitalize"
                          :href="`#${section.anchor}`"
                        >
                          {{section.title}}
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </div>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex class="px-5 py-4">
          <v-layout justify-center>
            <v-flex class="content-max-width px-2 py-4" xs12 md8>
              <div class="headline">
                <v-icon large class="open-quote">format_quote</v-icon>
                Our use of BugSeq as a rapid and robust bioinformatics tool has demonstrated the utility of user-friendly platforms for clinical diagnostics and public health service
                <v-icon large>format_quote</v-icon>
              </div>
              <div class="mt-3 headline">
                - <a href="https://doi.org/10.1371/journal.pone.0259712" target="_blank">Gauthier et al</a>
              </div>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex class="px-5 py-4 mb-5">
          <v-layout justify-center>
            <v-flex class="content-max-width">
              <v-layout wrap justify-space-between>
                <v-flex
                  xs12 md6
                  v-for="section in sections"
                  :key="section.anchor"
                  :id="section.anchor"
                  class="pa-2"
                >
                  <div class="pa-4 white border-radius">
                    <div class="title mb-3">{{section.title}}</div>
                    <div>{{section.text}}</div>
                    <ClickThroughDialogButton
                      buttonText="See Example Report"
                      :href="section.sampleReportHref"
                    >
                    </ClickThroughDialogButton>
                  </div>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex class="blue-grey darken-4 white--text px-5 py-4">
          <v-layout justify-center>
            <v-flex class="content-max-width">
              <v-layout align-center>
                <v-flex xs12>
                  <div class="font-weight-bold">Get Started</div>
                  <div class="mt-2">Ready to see BugSeq in action? Submit some data to see how it works</div>
                  <v-btn class="my-4 mx-0 text-capitalize" v-on:click="redirectToSignUp()">Get Started on BugSeq</v-btn>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>

<script>
import HeroImg from "@bugseq-site/www/src/assets/img/solutions/teamwork.jpg";
import MetagenomicsImg from "@bugseq-site/www/src/assets/img/solutions/metagenomics.png";
import HAIImg from "@bugseq-site/www/src/assets/img/solutions/hai.svg";
import SixteenSIMG from "@bugseq-site/www/src/assets/img/solutions/16s.png";
import ViralIMG from "@bugseq-site/www/src/assets/img/solutions/viral-amplicon.png";
import ClickThroughDialogButton from "@bugseq-site/www/src/components/ClickThroughDialogButton";
import { redirectToSignUp } from "@bugseq-site/www/src/utils";

export default {
  metaInfo: {
    title: "Solutions",
  },
  components: {
    ClickThroughDialogButton,
  },
  methods: {
    redirectToSignUp,
  },
  data() {
    return {
      HeroImg,
      sections: [
        {
          anchor: "metagenomics",
          title: "Clinical Metagenomics",
          text: "Identify organisms in clinical samples with precision, then predict AMR phenotype for every organism in the sample.",
          image: MetagenomicsImg,
          sampleReportHref: "/demo/clinical-metagenomics-sample.html",
        },
        {
          anchor: "hai",
          title: "Hospital Acquired Infections",
          text: "Characterize your pathogen in detail, including antimicrobial resistance, microbial typing and more.",
          image: HAIImg,
          sampleReportHref: "/demo/hospital-acquired-infections-summary.html",
        },
        {
          anchor: "sixteens",
          title: "16S/ITS Analysis",
          text: "Rapidly identify bacteria and fungi directly from a sample or culture.",
          image: SixteenSIMG,
          sampleReportHref: "/demo/sixteens.html",
        },
        {
          anchor: "viral-surveillance",
          title: "Viral Surveillance",
          text: "Accurately detect viral mutations for resistance testing or phylogenetics.",
          image: ViralIMG,
          sampleReportHref: "/demo/viral-surveillance.html",
        },
      ],
    }
  },
};
</script>

<style scoped>
.banner-text {
  text-decoration: none;
}

.blue-grey.darken-4.hero-text-box {
  opacity: 0.85;
  border: 1px solid white;
  border-color: white !important;
}

.content-max-width {
  max-width: 1200px;
}

.stock-image {
  filter: invert(100%);
}

.border-radius {
  border-radius: 5px;
}

.open-quote {
  transform: rotateY(180deg);
}
</style>
