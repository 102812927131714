<template>
  <v-app>
    <v-container fluid class="my-4">
      <v-layout wrap align-center justify-center row fill-height>
        <v-flex xs12 md10 lg6 class="py-2 card">
          <v-card>
            <v-card-title primary-title class="px-5 pt-5">
              <div class="subheading secondary--text my-3">
                August 24, 2021
              </div>
              <h3 class="display-1">
                BugSeq Analysis Platform Validated in Nanopore Metagenomic Study
                for Detecting Infections
              </h3>
            </v-card-title>
            <v-card-text class="px-5 pt-4">
              <p>
                VANCOUVER - Traditional methods of detecting and determining
                respiratory infections rely on PCR and microbial culture, which
                can only detect pathogens targeted by the test.
              </p>

              <p>
                Analysis by BugSeq, however, paired with metagenomic nanopore
                sequencing, bypasses the need to look for specific pathogens,
                instead using universal methods to detect viruses, bacteria,
                fungi and parasites. The work,
                <a
                  href="https://doi.org/10.1101/2021.08.13.21261922"
                  target="_blank"
                  >published on medRxiv this week</a
                >, demonstrates that this approach performs with near perfect
                accuracy for detecting SARS-CoV-2 from patients with infectious
                COVID-19.
              </p>

              <p>
                Researchers from the Future of Humanity Institute, the
                University of British Columbia, Vancouver General Hospital and
                the British Columbia Centre for Disease Control contributed to
                the project.
              </p>

              <p>
                Sam Chorlton, BugSeq co-founder and CEO, and a senior author of
                the study, said that “traditionally, metagenomic sequencing has
                required an expensive, large sequencer and a bioinformatician to
                analyse the data.” The key appeal of this test is the ability to
                detect any pathogen on a USB-sized, sub-$1000 sequencer with
                rapid and automated bioinformatics.
              </p>

              <p>
                In the study, researchers sequenced and analysed 43 samples from
                patients with and without COVID-19. The test was run against
                conventional PCR testing.
              </p>

              <p>
                Nanopore metagenomic sequencing, paired with BugSeq’s analysis,
                identified SARS-CoV-2 in 95 percent of patients with moderate to
                high levels of SARS-CoV-2. The accuracy of the test was 100
                percent on patients without COVID-19.
              </p>

              <p>
                BugSeq, which was founded in 2020, provides a third-generation
                sequencing analysis platform enabling any laboratory to rapidly
                identify known and novel pathogens. The solution uses curated
                databases containing more than 40,000 genomes to detect and
                characterize pathogens. Additionally, the suite automatically
                detects antimicrobial resistance and performs strain typing of
                organisms. The BugSeq pipeline was described in a
                <a
                  href="https://doi.org/10.1186/s12859-021-04089-5"
                  target="_blank"
                  >BMC Bioinformatics paper</a
                >
                published last year, where it was found to be the most accurate
                tool for third-generation sequencing data.
              </p>

              <h4 class="mb-3">
                About BugSeq
              </h4>
              <p>
                BugSeq is revolutionizing the use of metagenomics to improve
                health. BugSeq’s analysis suite delivers unparalleled data
                analytics and industry-leading expertise to convert
                third-generation sequencing data into actionable infectious
                disease testing and pathogen surveillance. By profiling tens of
                thousands of microorganisms from any specimen, BugSeq enables
                broad adoption of advanced technologies to improve health.
              </p>

              <p>
                For more information, visit <a href="/">bugseq.com</a> or reach
                out to us on
                <a href="https://twitter.com/bugseq" target="_blank">Twitter</a
                >,
                <a
                  href="https://www.linkedin.com/company/bugseq/"
                  target="_blank"
                  >LinkedIn</a
                >
                or
                <a href="mailto:press@bugseq.com">Email (press@bugseq.com)</a>.
              </p>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>

<script>
export default {
  metaInfo: {
    title:
      "BugSeq Analysis Platform Validated in Nanopore Metagenomic Study for Detecting Infections",
    // null is not working, pending update of vue-meta: https://github.com/nuxt/vue-meta/issues/527
    titleTemplate: "",
  },
};
</script>

<style scoped>
p {
  margin-bottom: 36px;
}
</style>
