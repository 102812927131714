import { render, staticRenderFns } from "./WhyBugSeq.vue?vue&type=template&id=48f20f79"
import script from "./WhyBugSeq.vue?vue&type=script&lang=js"
export * from "./WhyBugSeq.vue?vue&type=script&lang=js"
import style0 from "./WhyBugSeq.vue?vue&type=style&index=0&id=48f20f79&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports