<template>
  <v-card class="pa-4">
    <p class="home-section-title">Conferences</p>
    <p>
      BugSeq will be attending/sponsoring the following conferences. If you will be attending any of these, please <a href="mailto:contact@bugseq.com?subject=Conference Inquiry&body=Hi! I'd like to meet up at a conference you will be attending...">reach out</a> as we would love to schedule time to meet.
    </p>
    <v-container
      v-for="(conference, index) in conferences"
      :key="index"
    >
      <v-layout row wrap>
        <v-flex xs12 sm3>
          <a :href="conference.href" target="_blank">
            <v-img
              :src="conference.image"
              class="conferenceimg"
            ></v-img>
          </a>
        </v-flex>
        <v-flex xs12 sm9 class="pl-4">
          <div class="title">{{conference.name}}</div>
          <div class="my-2"><v-icon class="mr-2">date_range</v-icon>{{conference.dates}}</div>
          <div class="my-2"><v-icon class="mr-2">place</v-icon>{{conference.location}}</div>
        </v-flex>
      </v-layout>
    </v-container>
  </v-card>
</template>

<script>
import InformImg from "@/assets/img/resources/inform.jpg";
import ECCMIDImg from "@/assets/img/resources/eccmid.png";
import APHLImg from "@/assets/img/resources/aphl.jpg";
import LondonCallingImg from "@/assets/img/resources/london-calling.png";
import ASMMicrobeImg from "@/assets/img/resources/asm-microbe.jpg";
import AMPImg from "@/assets/img/resources/amp.jpg";

export default {
  metaInfo: {
    title: "Conferences",
    titleTemplate: "BugSeq | %s",
  },
  data() {
    return {
      conferences: [
        {
          name: "NEHA InFORM",
          dates: "January 22-24, 2024",
          location: "Washington, DC",
          image: InformImg,
          href: "https://www.neha.org/inform",
        },
        {
          name: "ECCMID",
          dates: "April 27-30, 2024",
          location: "Barcelona, Spain",
          image: ECCMIDImg,
          href: "https://www.eccmid.org/",
        },
        {
          name: "APHL",
          dates: "May 6-9, 2024",
          location: "Milwaukee, WI",
          image: APHLImg,
          href: "https://www.aphl.org/conferences/annualmeeting/Pages/default.aspx",
        },
        {
          name: "London Calling",
          dates: "May 21-24, 2024",
          location: "London, UK",
          image: LondonCallingImg,
          href: "https://nanoporetech.com/lc24",
        },
        {
          name: "ASM Microbe",
          dates: "June 13-17, 2024",
          location: "Atlanta, GA",
          image: ASMMicrobeImg,
          href: "https://asm.org/events/asm-microbe/home",
        },
        {
          name: "AMP",
          dates: "November 19-23, 2024",
          location: "Vancouver, BC",
          image: AMPImg,
          href: "http://amp24.amp.org/",
        },
      ],
    }
  },
};
</script>

<style scoped>
.conferenceimg {
  border-radius: 5px;
}
</style>
