<template>
  <v-container class="pa-0">
    <v-layout wrap align-center justify-center row fill-height>
      <v-flex sm5 md4 xl3 class="pr-5 hidden-xs-only">
        <v-img
          :src="require('@bugseq-site/www/src/assets/img/home/building_analysis.png')"
          :lazy-src="require('@bugseq-site/www/src/assets/img/home/building_analysis.png')"
          width="100%"
        />
      </v-flex>
      <v-flex xs12 sm7 md8 xl9>
        <p class="main-text pb-2">
          <span
            class="rotating"
            v-for="(text, idx) in rotatingTexts"
            v-if="selected === idx"
            ><span :class="['rotatingText', colorClasses[idx]]">{{
              text
            }}</span></span
          >
          sequencing analysis.
        </p>
        <p class="my-4 font-weight-bold">
          Supercharge your lab. Go from upload to actionable results in an hour.
          Get industry-leading accuracy.
        </p>
        <p class="my-4">
          BugSeq is a fully automated bioinformatics platform for microbiology
          labs. Wherever your lab is at, BugSeq will help you adopt, leverage
          and scale DNA sequencing.
        </p>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class HomeStartScreen extends Vue {
  private colorClasses: string[] = [
    "deep-purple--text",
    "blue--text text--darken-2",
    "pink--text text--lighten-2",
    "teal--text text--darken-1",
    "purple--text",
    "green--text",
  ];
  private rotatingTexts: string[] = [
    "Rapid",
    "Accurate",
    "Affordable",
    "Simple",
    "Automatic",
    "Validated",
  ];
  private selected: number = 0;
  private interval: number;

  constructor() {
    super();
    this.interval = setInterval(this.transition.bind(this), 1500);
  }

  private transition(curr: number) {
    this.selected = (this.selected + 1) % this.rotatingTexts.length;
  }

  private beforeDestroy() {
    clearInterval(this.interval);
  }
}
</script>

<style scoped>
.main-text {
  font-size: 36px;
  font-weight: 700;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.rotating {
  border-bottom: 2px solid black;
  width: 9ch;
  text-align: center;
  display: inline-block;
}

.rotatingText {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  -webkit-animation-duration: 0.1s;
  animation-duration: 0.1s;
  -webkit-animation-delay: 1.8s;
  animation-delay: 1.8s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}
</style>
