<template>
  <div>Redirecting...</div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { getAppUrl } from "@bugseq-site/shared/src/env";

@Component
export default class RedirectToApp extends Vue {
  private mounted() {
    const path = this.$route.fullPath
    window.location.href = `${getAppUrl()}${path}`
  }
}
</script>
