<template>
  <v-container class="pa-0">
    <v-layout wrap align-center justify-center row fill-height>
      <v-flex xs12 class="text-xs-center">
        <p class="home-section-title my-4">
          Get started with BugSeq
        </p>
      </v-flex>

      <v-flex xs10 sm8 class="text-xs-center">
        <p>
          Register to receive 3 trial analyses and experience the power of
          BugSeq! No commitment and no credit card required. Plans also available for academic users. See pricing details
          <a class="white--text" href="/pricing">here</a>.
        </p>
      </v-flex>
      <v-flex xs12 class="text-xs-center">
        <v-btn @click="redirectToSignUp()" class="ml-2 text-capitalize">Register</v-btn>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import { redirectToSignUp } from "@bugseq-site/www/src/utils";

@Component({
  methods: {
    redirectToSignUp,
  }
})
export default class Register extends Vue {}
</script>
