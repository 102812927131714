<template>
  <v-app>
    <v-container fluid class="my-4">
      <v-layout wrap align-center justify-center row fill-height>
        <v-flex xs12 md10 lg6 class="py-2 card">
          <v-card>
            <v-card-title primary-title class="px-5 pt-5">
              <div class="subheading secondary--text my-3">
                May 3, 2022
              </div>
              <h3 class="display-1">
                BugSeq announces BARDA DRIVe award to develop next-gen
                diagnostics for any respiratory RNA virus
              </h3>
            </v-card-title>
            <v-card-text class="px-5 pt-4">
              <p>
                <a href="https://bugseq.com" target="_blank"
                  >BugSeq Bioinformatics ("BugSeq")</a
                >, an innovative biotech company developing next-generation
                microbiology assays, announces it has entered a public-private
                partnership with the U.S. Biomedical Advanced Research and
                Development Authority's (BARDA) Division of Research,
                Innovation, and Ventures (DRIVe) to develop a new diagnostic
                that covers all existing and new respiratory RNA viruses in a
                single test. BARDA is part of the office of the Assistant
                Secretary for Preparedness and Response at the U.S. Department
                of Health and Human Services.
              </p>
              <p>
                Most of today’s clinical diagnostic tools are designed to detect
                the presence of a specific and known pathogen. These targeted
                assays – like the PCR and antigen tests that have become widely
                used during the COVID-19 pandemic – meet a critical need for
                diagnostics. Yet targeted diagnostic tests are reactive by
                design and typically are not available until after an outbreak
                has begun. Additionally, significant time and effort are needed
                to develop, verify, and validate such tests and obtain
                regulatory approval. When faced with a public health emergency
                of pandemic potential, time is crucial, and having an agnostic
                diagnostic test – a diagnostic that can identify any known or
                novel pathogen – can help accelerate a response.
              </p>
              <p>
                Next-generation sequencing (NGS) technology is a promising
                platform for the development of agnostic diagnostic tests. NGS
                assays can sequence all types of DNA or RNA in a clinical sample
                and can use that information to identify any pathogen that is
                present, including viruses such as SARS-CoV-2. Widely
                distributed NGS-based agnostic diagnostic capabilities could
                enable early identification of infections from pathogens of
                concern and could also provide a broad capability to identify
                and track variants as they arise.
              </p>
              <p>
                Well-known pathogenic respiratory infections include those
                caused by influenza viruses, respiratory syncytial virus (RSV),
                rhinoviruses, the severe acute respiratory syndrome coronavirus
                (SARS-CoV), and the Middle East respiratory syndrome coronavirus
                (MERS-CoV). Many of these pathogens may cause patients to
                present with a similar set of early onset symptoms. The standard
                of care currently is grounded in assessing symptoms and then
                performing targeted testing for individual viruses. NGS-based
                agnostic diagnostic technology has the potential to change the
                paradigm of care by leading with a single untargeted diagnostic
                to detect any and every respiratory pathogen. Therefore, these
                tests could provide information critical to timely and
                actionable patient care.
              </p>
              <p>
                BugSeq is working with University of British Columbia
                researchers to bring this innovative technology closer to
                reality, starting with an NGS-based diagnostic that is intended
                for the detection of all known and unknown respiratory RNA
                viruses. The team is building off of previously published work,
                where they demonstrated the ability to detect SARS-CoV-2 from
                patients with COVID-19 using a portable, NGS-based agnostic
                assay. Subsequent work by the team additionally demonstrated the
                ability to detect novel and emerging pathogens, using the BugSeq
                bioinformatics platform, in a rapid and agnostic manner.
              </p>
              <p>
                Under the BARDA DRIVe-funded project, BugSeq will optimize an
                assay for commercial clinical use, including lowering the
                sample-to-result time to under 12 hours, reducing interference
                from host RNA, and performing analytical validation of the
                platform using both contrived and clinical respiratory samples.
                Each of these optimizations will address some of the most
                critical constraints on NGS assays, thus yielding faster
                diagnosis, categorization, and identification of new and
                emerging pathogens.
              </p>
              <p>
                The emergence of another respiratory RNA virus with pandemic
                potential is highly likely, and public health and response
                communities will need comprehensive diagnostic tests to quickly
                detect the next novel pathogen. Accelerating the development of
                these NGS-based agnostic diagnostics could help bolster global
                preparedness for future outbreaks and potentially enable other
                opportunities for infectious disease diagnostics.
              </p>
              <p>
                This project has been supported in whole or in part with federal
                funds from the U.S. Department of Health and Human Services;
                Office of the Assistant Secretary for Preparedness and Response;
                Biomedical Advanced Research and Development Authority (BARDA),
                under contract number 75A50122C00027.
              </p>

              <b>About BugSeq</b>

              <p>
                BugSeq is leveraging advanced sequencing technologies to
                revolutionize infectious disease testing and pathogen
                surveillance. BugSeq’s analysis suite delivers unparalleled data
                analytics and industry-leading expertise to convert sequencing
                data into actionable results. By profiling tens of thousands of
                microorganisms and markers of antimicrobial resistance from any
                specimen, BugSeq enables broad adoption of sequencing to improve
                health.
              </p>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>

<script>
export default {
  metaInfo: {
    title:
      "BugSeq announces BARDA DRIVe award to develop next-gen diagnostics for any respiratory RNA virus",
    titleTemplate: "",
  },
};
</script>

<style scoped>
p {
  margin-bottom: 36px;
}
</style>
