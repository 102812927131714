<template>
  <v-toolbar
    app
    color="white"
    height="55px"
    scroll-off-screen
    class="google-font"
  >
    <div class="nav-logo-wrapper py-3">
      <img
        :src="require('@bugseq-site/shared/src/assets/img/nav-logo.png')"
        class="nav-logo"
      />
    </div>
    <v-spacer class="hidden-md-and-up"></v-spacer>
    <v-toolbar-items class="ml-4">
      <v-menu
        open-on-hover
        offset-y
        v-for="navSection in navSections"
        :key="navSection.section"
      >
        <template v-slot:activator="{ on }">
          <v-layout v-on="on">
            <v-btn
              class="ml-0 text-capitalize"
              :class="{'hidden-xs-only': ['Resources', 'About'].includes(navSection.section)}"
              flat
              :href="navSection.href"
            >
              {{navSection.section}}
            </v-btn>
          </v-layout>
        </template>

        <v-list>
          <v-list-tile
            v-for="(item, index) in navSection.links"
            :key="index"
            :href="item.href"
            :target="item.target"
          >
            <v-list-tile-title>{{ item.title }}</v-list-tile-title>
          </v-list-tile>
        </v-list>
      </v-menu>
    </v-toolbar-items>
    <v-spacer class="hidden-sm-and-down"></v-spacer>
    <v-toolbar-items class="hidden-sm-and-down">
      <v-layout>
        <v-btn
          class="text-capitalize"
          flat
          href="/quote"
        >
          Request Quote
        </v-btn>
        <v-btn
          class="text-capitalize mr-2"
          flat
          :href="registerUrl"
        >
          Get Started
        </v-btn>
        <v-btn
          class="elevation-1 text-capitalize"
          color="primary"
          :href="loginUrl"
        >
          Login
        </v-btn>
      </v-layout>
    </v-toolbar-items>
  </v-toolbar>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import { getAppUrl } from "@bugseq-site/shared/src/env"

@Component
export default class Toolbar extends Vue {
  private navSections = [
    {
      section: "About",
      href: "/",
      links: [
        {
          title: "The BugSeq Advantage",
          href: "/#whybugseq",
        },
        {
          title: "How It Works",
          href: "/#about",
        },
        {
          title: "Contact Us",
          href: "/#contact",
        },
        {
          title: "Pricing",
          href: "/pricing",
        },
      ],
    },
    {
      section: "Solutions",
      href: "/solutions",
      links: [
        {
          title: "Metagenomics",
          href: "/solutions#metagenomics",
        },
        {
          title: "Hospital Acquired Infections",
          href: "/solutions#hai",
        },
        {
          title: "16S/ITS",
          href: "/solutions#sixteens",
        },
        {
          title: "Viral Surveillance",
          href: "/solutions#viral-surveillance",
        },
        {
          title: "Pathogen Pipelines",
          href: "/pathogen-pipelines",
        },
      ],
    },
    {
      section: "Data Analysis",
      href: "/analysis",
      links: [
        {
          title: "Taxonomic Classification",
          href: "/analysis#taxonomic-classification",
        },
        {
          title: "Curated Database",
          href: "/analysis#databases",
        },
        {
          title: "Antimicrobial Resistance",
          href: "/analysis#amr",
        },
        {
          title: "Outbreak Analysis",
          href: "/analysis#outbreak-analysis",
        },
        {
          title: "Plasmid Detection",
          href: "/analysis#plasmid-detection",
        },
        {
          title: "Quality Control",
          href: "/analysis#quality-control",
        },
        {
          title: "Subtyping",
          href: "/analysis#subtyping",
        },
        {
          title: "Pathogen Identification",
          href: "/analysis#pathogen-identification",
        },
      ],
    },
    {
      section: "Resources",
      links: [
        {
          title: "Publications",
          href: "/publications",
        },
        {
          title: "Documentation",
          href: "https://docs.bugseq.com",
          target: "_blank",
        },
        {
          title: "Blog",
          href: "https://docs.bugseq.com/blog",
          target: "_blank",
        },
        {
          title: "Conferences",
          href: "/resources/conferences",
        },
        {
          title: "Support",
          href: "/resources/support",
        },
      ],
    },
  ]
  private registerUrl = `${getAppUrl()}/register`
  private loginUrl = `${getAppUrl()}/login`
}
</script>

<style>
.nav-logo-wrapper {
  height: 100%;
  cursor: pointer;
}

.nav-logo {
  height: 100%;
}
</style>
