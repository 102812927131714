<template>
  <v-container fluid class="my-4 google-font">
    <v-layout wrap align-center justify-center row fill-height>
      <v-flex xs12 md10 lg8 xl6 class="py-2 card">
        <v-card>
          <v-card-title primary-title class="px-5 pt-5 pb-5">
            <v-layout row justify-center align-center>
              <v-flex shrink>
                <v-layout row fill-height align-center>
                  <img
                    :src="require('@bugseq-site/www/src/assets/img/demo/pacbio-logo.svg')"
                    class="logo"
                  />
                </v-layout>
              </v-flex>
              <v-flex shrink>
                <v-layout align-center justify-center class="mx-4">
                  +
                </v-layout>
              </v-flex>
              <v-flex shrink>
                <v-layout row fill-height align-center>
                  <img
                    :src="require('@bugseq-site/shared/src/assets/img/nav-logo.png')"
                    class="logo bugseq-logo"
                  />
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card-title>
          <v-card-text class="px-5 pt-2">
            <v-layout row align-center wrap class="mb-5">
              <v-flex xs12 sm7 class="pr-5">
                <h3 class="display-1">
                  BugSeq: Tuned for PacBio
                </h3>
                <p class="pt-2">
                  BugSeq has collaborated with PacBio to ensure optimal analysis for
                  PacBio HiFi sequencing data.
                </p>
              </v-flex>
              <v-flex xs12 sm5 class="grey lighten-4 border-radius pa-4 mt-4">
                <div class="headline">Try it out</div>
                <div class="my-2">Experience BugSeq's analysis on your own PacBio data with a free trial.</div>
                <v-btn
                  color="primary"
                  class="text-capitalize mx-0"
                  @click="redirectToSignUp"
                >Get Started</v-btn>
              </v-flex>
            </v-layout>
            <p>
              Two datasets, representing metagenomic and bacterial isolate
              sequencing, are presented for demonstration:
            </p>
            <ul>
              <li>
                Metagenomic: The
                <a
                  href="https://zymoresearch.eu/products/zymobiomics-gut-microbiome-standard"
                  target="_blank"
                  >ZymoBIOMICS Gut Microbiome Standard</a
                >
                (D6331), which contains 21 different strains mimicking the human
                gut microbiome. Accession:
                <a
                  href="https://www.ncbi.nlm.nih.gov/sra/?term=SRR13128014"
                  target="_blank"
                  >SRR13128014</a
                >.
              </li>
              <li>
                Bacterial isolate: The first six barcodes from the PacBio
                <a
                  href="https://downloads.pacbcloud.com/public/dataset/2021-11-Microbial-96plex"
                  target="_blank"
                  >Food safety and infectious microbes – 96 plex</a
                >
                dataset, which contains pathogens of clinical and public health
                importance.
              </li>
            </ul>
            <PacBioSection
              v-for="section in sections"
              :key="section.title"
              :title="section.title"
              :description="section.description"
              :metagenomicLinks="section.metagenomicLinks"
              :bacterialIsolateLinks="section.bacterialIsolateLinks"
            />
            <h4 class="title">Interested?</h4>
            <p class="mt-3">
              Interested in trying BugSeq on your PacBio data?
              <a @click="goToRegister()">Register</a> to get started.
            </p>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { redirectToSignUp } from "@bugseq-site/www/src/utils";
import PacBioSection from "@bugseq-site/www/src/components/demo/PacBioSection.vue";

@Component({
  components: {
    PacBioSection,
  },
  methods: {
    redirectToSignUp,
  },
  metaInfo() {
    return {
      title: "PacBio",
      meta: [
        {
          vmid: "description",
          name: "description",
          content:
            "See how BugSeq identifies microbes in metagenomic data and automatically performs advanced analyses.",
        },
      ],
    };
  },
})
export default class PacBio extends Vue {
  private sections = [
    {
      title: "Summary Report",
      description:
        "The summary report gives an overview of the  submitted samples. It provides a glance at all critical data- quality control, metagenomic classification, assembly statistics, and more.",
      metagenomicLinks: [
        {
          text: "Summary Report",
          href:
            "/demo/pacbio/metagenomic/summary_reports/summary_report-880c588d-3a20-4b06-abad-4efc81dedba3.html",
        },
      ],
      bacterialIsolateLinks: [
        {
          text: "Summary Report",
          href:
            "/demo/pacbio/bacterial_isolate/summary_reports/summary_report-ba944665-5a5f-4a03-9311-eb74d0b7e9ca.html",
        },
      ],
    },
    {
      title: "Sample Reports",
      description:
        "A report is generated for each sample included in the analysis. In this report, you will find more detailed information, including organism identification, plasmid detection, antimicrobial resistance prediction, and multilocus sequence typing.",
      metagenomicLinks: [
        {
          text: "Sample SRR13128014",
          href: "/demo/pacbio/metagenomic/sample_reports/SRR13128014.html",
        },
      ],
      bacterialIsolateLinks: [
        {
          text: "Sample BC2001",
          href:
            "/demo/pacbio/bacterial_isolate/sample_reports/m64004_210929_143746.bc2001.fastq.html",
        },
        {
          text: "Sample BC2002",
          href:
            "/demo/pacbio/bacterial_isolate/sample_reports/m64004_210929_143746.bc2002.fastq.html",
        },
        {
          text: "Sample BC2003",
          href:
            "/demo/pacbio/bacterial_isolate/sample_reports/m64004_210929_143746.bc2003.fastq.html",
        },
        {
          text: "Sample BC2004",
          href:
            "/demo/pacbio/bacterial_isolate/sample_reports/m64004_210929_143746.bc2004.fastq.html",
        },
        {
          text: "Sample BC2005",
          href:
            "/demo/pacbio/bacterial_isolate/sample_reports/m64004_210929_143746.bc2005.fastq.html",
        },
        {
          text: "Sample BC2006",
          href:
            "/demo/pacbio/bacterial_isolate/sample_reports/m64004_210929_143746.bc2006.fastq.html",
        },
      ],
    },
    {
      title: "Metagenomic Classification",
      description:
        "The metagenomic classification will give you an in-depth look at what was found in the sample with a Krona plot.",
      metagenomicLinks: [
        {
          text: "Metagenomic Classification",
          href:
            "/demo/pacbio/metagenomic/metagenomic_classification-880c588d-3a20-4b06-abad-4efc81dedba3.html",
        },
      ],
      bacterialIsolateLinks: [
        {
          text: "Metagenomic Classification",
          href:
            "/demo/pacbio/bacterial_isolate/metagenomic_classification-ba944665-5a5f-4a03-9311-eb74d0b7e9ca.html",
        },
      ],
    },
  ];
}
</script>

<style scoped>
.logo {
  height: 24px;
}

@media (min-width: 600px) {
  .logo {
    height: 32px;
  }
}

.bugseq-logo {
  margin-top: 2px;
}

.border-radius {
  border-radius: 5px;
}
</style>
