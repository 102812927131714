<template>
  <v-container id="contact" class="pa-0" fill-height>
    <v-layout wrap row>
      <v-flex xs12>
        <p class="home-section-title my-4">
          Get in touch
        </p>
      </v-flex>
      <v-flex>
        <p class="ml-1">
          If you have any questions about the platform, please do not hesitate to get in touch.
        </p>
      </v-flex>
      <v-flex xs12>
        <ContactForm />
      </v-flex>
      <v-flex xs12 class="my-3">
        <v-layout row wrap justify-center mr-5>
          <v-flex shrink mx-4>
            <v-layout>
              <v-icon medium class="mr-2">create</v-icon>
              <a :href="prefix + decoded">{{ decoded }}</a>
            </v-layout>
          </v-flex>
          <v-flex shrink mx-4>
            <v-layout>
              <v-icon medium class="mr-2">phone</v-icon>
              <a :href="phonelink">
                &#49;&#32;&#40;&#56;&#55;&#55;&#41;&#32;&#51;&#51;&#52;&#45;&#56;&#55;&#49;&#53;
              </a>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import ContactForm from "@bugseq-site/www/src/components/home/ContactForm.vue";

export default {
  components: {
    ContactForm,
  },
  data: () => ({
    decoded: atob("Y29udGFjdEBidWdzZXEuY29t"),
    prefix: atob("bWFpbHRvOg=="),
    phonelink: atob("dGVsOjEtODc3LTMzNC04NzE1Cg=="),
  }),
};
</script>
