<template>
  <v-container class="pa-0">
    <p class="home-section-title mb-4">Why BugSeq?</p>
    <v-layout
      v-for="valueProp in valueProps"
      :key="valueProp.title"
      wrap
      class="my-4"
    >
      <v-flex>
        <v-card>
          <v-layout wrap class="py-2 pl-4">
            <v-flex xs12 md2 class="font-weight-bold py-2 pr-4">{{
              valueProp.title
            }}</v-flex>
            <v-flex xs12 md10 class="py-2 pr-4" v-html="valueProp.body" />
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      valueProps: [
        {
          title: "Fully Automated",
          body:
            "Unlock detailed analyses without the need to grow your bioinformatics team.",
        },
        {
          title: "24/7 Support",
          body:
            "Our team is always available to answer questions on methods and results.",
        },
        {
          title: "Fast & Scalable",
          body:
            "Cloud-based infrastructure delivers rapid analysis for workloads of any size.",
        },
        {
          title: "Private & Secure",
          body:
            "BugSeq follows industry best-practices to securely process sensitive data.",
        },
      ],
    };
  },
};
</script>

<style>
.no_underline {
  text-decoration: none;
}
</style>
