import { RouteConfig } from 'vue-router'

import Home from '@bugseq-site/www/src/views/Home.vue'
import Solutions from '@bugseq-site/www/src/views/Solutions.vue'
import Analysis from '@bugseq-site/www/src/views/Analysis.vue'
import GenericCardPage from '@bugseq-site/www/src/views/GenericCardPage.vue'
import Conferences from '@bugseq-site/www/src/views/resources/Conferences.vue'
import Support from '@bugseq-site/www/src/views/resources/Support.vue'
import Demo from '@bugseq-site/www/src/views/demo/Demo.vue'
import PacBio from '@bugseq-site/www/src/views/demo/PacBio.vue'
import Careers from '@bugseq-site/www/src/views/Careers.vue'
import PathogenPipelines from '@bugseq-site/www/src/views/PathogenPipelines.vue'
import PressRelease20211208 from '@bugseq-site/www/src/views/PressRelease-12-08-2021.vue'
import PressRelease20220503 from '@bugseq-site/www/src/views/PressRelease-05-03-2022.vue'
import PressRelease20220924 from '@bugseq-site/www/src/views/PressRelease-2022-09-24.vue'
import PressRelease20230111 from '@bugseq-site/www/src/views/PressRelease-2023-01-11.vue'
import PressRelease20240711 from '@bugseq-site/www/src/views/PressRelease-2024-07-11-barda.vue'
import Pricing from '@bugseq-site/www/src/views/Pricing.vue'
import Quote from '@bugseq-site/www/src/views/Quote.vue'
import Publications from '@bugseq-site/www/src/views/Publications.vue'
import RedirectToApp from '@bugseq-site/www/src/views/RedirectToApp.vue'

const routes: RouteConfig[] = [
  {
    path: '/',
    component: Home
  },
  {
    path: '/solutions',
    component: Solutions
  },
  {
    path: '/analysis',
    component: Analysis
  },
  {
    path: '/resources',
    redirect: '/resources/conferences',
    component: GenericCardPage,
    children: [
      {
        path: 'conferences',
        name: 'conferences',
        component: Conferences
      },
      {
        path: 'support',
        name: 'support',
        component: Support
      }
    ]
  },
  {
    path: '/bugseq-demos/2024/demo',
    component: Demo
  },
  {
    path: '/pacbio',
    component: PacBio
  },
  {
    path: '/careers',
    component: Careers
  },
  {
    path: '/pathogen-pipelines',
    component: PathogenPipelines
  },
  {
    path:
      '/news/BugSeq-Analysis-Platform-Validated-in-Nanopore-Study-Detecting-Infections',
    component: PressRelease20211208
  },
  {
    path: '/news/BugSeq-BARDA-DRIVe-diagnostics-any-respiratory-virus',
    component: PressRelease20220503
  },
  {
    path: '/news/BugSeq-PGC-Mindanao-partnership',
    component: PressRelease20220924
  },
  {
    path: '/news/BugSeq-PacBio-partnership',
    component: PressRelease20230111
  },
  {
    path: '/news/2024/07/11/BugSeq-BARDA-AI-bioinformatics',
    component: PressRelease20240711
  },
  {
    path: '/pricing',
    component: Pricing
  },
  {
    path: '/quote',
    component: Quote
  },
  {
    path: '/publications',
    component: Publications
  },
  {
    path: '/app/*',
    component: RedirectToApp
  },
  {
    path: '/free',
    redirect: '/academic'
  },
  {
    path: '/academic',
    component: RedirectToApp
  },
  {
    path: '/*',
    redirect: '/'
  }
]
export default routes
