<template>
  <div id="app">
    <v-app>
      <Toolbar />
      <v-content>
        <router-view />
      </v-content>
      <Footer />
      <NotificationsManager></NotificationsManager>
    </v-app>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import NotificationsManager from "@bugseq-site/www/src/components/NotificationsManager.vue";
import Toolbar from "@bugseq-site/www/src/components/Toolbar.vue";
import Footer from "@bugseq-site/www/src/components/Footer.vue";

// NOTE: these are also specified in index.html
const metaDescription = "BugSeq uses evidence-based, pathogen-specific pipelines to produce actionable microbiology reports.";
const metaKeywords = ['BugSeq', 'bioinformatics', 'microbiology', 'genomics', 'genomic', 'metagenomic', 'metagenomics', 'nanopore', 'MinION', 'Flongle', 'PromethION', ' GridION', 'illumina', 'sequencing', 'clinical', 'medical', 'automated', 'automatic', 'bacteria', 'bacterial', 'virus', 'viral', 'parasite', 'parasitic', 'fungi', 'fungal', 'fungus'];

@Component({
  metaInfo() {
    return {
      title: "BugSeq",
      titleTemplate: "BugSeq | %s",
      meta: [
        {
          vmid: "description",
          name: "description",
          content: metaDescription,
        },
        {
          name: "keywords",
          content: metaKeywords.join(","),
        },
        { name: "viewport", content: "width=device-width, initial-scale=1" },

        // Social media tags
        { name: "twitter:card", content: "summary" },
        { name: "og:title", content: "BugSeq" },
        {
          name: "og:description",
          content: metaDescription,
        },
        {
          name: "og:image",
          content: "https://bugseq.com/img/icons/favicon-32x32.png",
        },
        { name: "og:url", content: "https://bugseq.com" },
      ],
    };
  },
  components: {
    NotificationsManager,
    Toolbar,
    Footer,
  },
})
export default class App extends Vue {}
</script>
