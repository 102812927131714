<template>
  <div>
    <v-form
      v-if="!submitted"
      ref="form"
      v-model="valid"
    >
      <v-layout column>
        <v-flex xs12 md6>
          <v-text-field
            v-model="firstName"
            prepend-icon="person"
            :rules="nameRules"
            label="First Name"
            required
          ></v-text-field>

          <v-text-field
            v-model="lastName"
            prepend-icon="person"
            :rules="nameRules"
            label="Last Name"
            required
          ></v-text-field>

          <v-text-field
            v-model="email"
            prepend-icon="email"
            :rules="emailRules"
            label="E-mail"
            required
          ></v-text-field>

          <v-textarea
            prepend-icon="chat_bubble"
            :rules="[(v) => !!v || 'Message is required']"
            v-model="message"
            label="Message"
            required
          ></v-textarea>
        </v-flex>
        <v-flex text-xs-center pb-4>
          <vue-recaptcha
            sitekey="6Lczo9AUAAAAADvoBCZ77cbrDA6gJNUZAK7Y5efj"
            :loadRecaptchaScript="true"
            @verify="submit"
          >
            <v-btn
              :disabled="!valid"
              color="primary"
              class="mr-4 text-capitalize"
            >
              Submit
            </v-btn>
          </vue-recaptcha>
        </v-flex>
        <v-flex text-xs-center>
          <p class="caption mt-2">
            This site is protected by reCAPTCHA and the Google
            <a href="https://policies.google.com/privacy">Privacy Policy</a>
            and
            <a href="https://policies.google.com/terms">Terms of Service</a>
            apply.
          </p>
        </v-flex>
      </v-layout>
    </v-form>
    <v-layout
      column
      class="py-5"
      v-else
    >
      <v-icon x-large>check_circle</v-icon>
      <v-flex text-xs-center class="mt-2">
        <p>Your message has been received!</p>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import VueRecaptcha from "vue-recaptcha";
import { dispatchSubmitForm } from "@bugseq-site/www/src/store/modules/api/actions";

export default {
  components: { VueRecaptcha },
  data: () => ({
    valid: true,
    message: "",
    firstName: "",
    lastName: "",
    nameRules: [(v) => !!v || "Name is required"],
    email: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    submitted: false,
  }),
  methods: {
    async submit() {
      await dispatchSubmitForm(this.$store, {
        body: {
          firstname: this.firstName,
          lastname: this.lastName,
          email: this.email,
          message: this.message,
        },
        form: "contact_us",
      });
      this.$refs.form.reset();
      this.submitted = true;
    },
  },
};
</script>

<style>
.grecaptcha-badge {
  display: none;
}
</style>
