<template>
  <v-app>
    <v-container fluid class="my-4">
      <v-layout wrap align-center justify-center row fill-height>
        <v-flex xs12 md10 lg6 class="py-2 card">
          <v-card>
            <v-card-title primary-title class="px-5 pt-5">
              <div class="subheading secondary--text my-3">
                September 24, 2022
              </div>
              <h3 class="display-1">
                BugSeq announces partnership with Philippine Genome Center
                Mindanao, supported by Gates Foundation, to improve LMIC
                genomics
              </h3>
            </v-card-title>
            <v-card-text class="px-5 pt-4">
              <p>
                <a href="https://bugseq.com" target="_blank"
                  >BugSeq Bioinformatics ("BugSeq")</a
                >, an innovative biotech company developing next-generation
                microbiology assays, announces it has entered into a partnership
                with Philippine Genome Center (PGC) Mindanao to enable
                genomic-based infectious disease surveillance in the
                Philippines. PGC Mindanao is a genomics-focused
                multidisciplinary research unit in UP Diliman, Quezon City,
                leading adoption of advanced technologies to improve health,
                agriculture and the environment.
              </p>

              <p>
                The project, funded by the Bill and Melinda Gates Foundation
                through the Public Health Alliance for Genomic Epidemiology
                (PHA4GE), aims to implement standardized bioinformatics
                practices, pipelines, and data structures for infectious disease
                surveillance.
              </p>

              <p>
                “This grant will help strengthen our capabilities for
                bioinformatics research”, said former PGC Mindanao Director and
                now University of the Philippines (UP) Mindanao Chancellor, Dr.
                Lyre Anni E. Murao. “We build stronger research engagements, one
                step at a time.”
              </p>
              <p>
                Initially proposed by Dr. Murao, the project will now have its
                new principal investigator, Dr. Ritchie Mae T. Gamot, who is
                also a technical expert in the Bioinformatics Division of PGC
                Mindanao. She is joined by PGC Mindanao Bioinformatics Division
                Research staff, Paul Lorenzo A. Gaite, MSc., and PGC Mindanao
                Administrative Support Staff, Ms. Marian S. Luayon.
              </p>

              <p>
                Through the grant, PGC Mindanao will implement the project under
                Theme 2 (SARS-CoV-2 sequencing laboratories in low- to
                middle-income countries in Africa and Asia), with provisions for
                hiring technical experts and building computing infrastructures,
                such as structured LAN cabling for internet connectivity and
                additional storage and memory devices, to support bioinformatics
                analysis in the center. BugSeq will provide data analysis,
                including quality control, consensus sequence generation,
                variant calling and identification, and automated report
                generation for the project. BugSeq will also assist PGC Mindanao
                in data submission to online sequence databases.
              </p>

              <b>About the PHA4GE Sub-grant</b>
              <p>
                PHA4GE is a global consortium founded on principles of fairness,
                openness, and transparency. In 2021, PHA4GE announced a call for
                sub-grants funded by the Bill and Melinda Gates Foundation, with
                the aim to promote sustainable development in bioinformatics in
                support of public health.
              </p>
              <b>About the Bill and Melinda Gates Foundation</b>

              <p>
                The Bill & Melinda Gates Foundation (BMGF) is an American
                private foundation founded by Bill Gates and Melinda French
                Gates in 2000. The primary goals of the foundation include
                enhancing healthcare, reducing extreme poverty across the world,
                and expanding educational opportunities and access to
                information technology. Since its establishment, the foundation
                has assisted and supported developments in the areas of social,
                health, and education. In 2020, it was reported to be the second
                largest charitable foundation in the world.
              </p>
              <b>About BugSeq</b>

              <p>
                BugSeq is leveraging advanced sequencing technologies to
                revolutionize infectious disease testing and pathogen
                surveillance. BugSeq’s analysis suite delivers unparalleled data
                analytics and industry-leading expertise to convert sequencing
                data into actionable results. By profiling tens of thousands of
                microorganisms and markers of antimicrobial resistance from any
                specimen, BugSeq enables broad adoption of sequencing to improve
                health.
              </p>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>

<script>
export default {
  metaInfo: {
    title:
      "BugSeq announces partnership with Philippine Genome Center Mindanao, supported by Gates Foundation, to improve LMIC genomics",
    titleTemplate: "",
  },
};
</script>

<style scoped>
p {
  margin-bottom: 36px;
}
</style>
