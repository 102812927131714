// errors really don't play well with openapi-fetch
// revisit when we upgrade openapi-fetch (last noted Jan 11, 2024 - there is an updated version available)
/* eslint @typescript-eslint/no-throw-literal: 0 */
/* eslint @typescript-eslint/no-non-null-assertion: 0 */
/* eslint @typescript-eslint/no-unnecessary-type-assertion: 0 */
import { ActionContext } from 'vuex'
import { getStoreAccessors } from 'typesafe-vuex'
import { RootState } from '../../state'
import { ApiState } from './state'
import { components } from '@bugseq-site/www/src/lib/api/api'
import { BugSeqApiError, isRawBugSeqApiError } from '@bugseq-site/shared/src/lib/api/errors'
import { dispatchAddNotification } from '@bugseq-site/www/src/store/modules/notifications/actions'

type ApiContext = ActionContext<ApiState, RootState>

export const actions = {
  async checkApiError (context: ApiContext, payload: any) {
    let errMsg = 'Error'
    if (payload instanceof BugSeqApiError) {
      errMsg += `: ${payload.message}`
    }
    await dispatchAddNotification(context, {
      color: 'error',
      content: errMsg
    })
  },
  async submitForm (
    context: ApiContext,
    payload: { form: components['schemas']['Form'], body: any }
  ) {
    try {
      const { error } = await context.state.client.POST(
        '/v1/contact/hs',
        {
          body: payload.body,
          params: { query: { form: payload.form } }
        }
      )
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
    } catch (e) {
      await dispatchCheckApiError(context, e)
      throw e
    }
  }
}

const { dispatch } = getStoreAccessors<ApiState, RootState>('api')

export const dispatchCheckApiError = dispatch(actions.checkApiError)
export const dispatchSubmitForm = dispatch(actions.submitForm)
