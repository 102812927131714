import { render, staticRenderFns } from "./HeroQuote.vue?vue&type=template&id=52d34756&scoped=true"
var script = {}
import style0 from "./HeroQuote.vue?vue&type=style&index=0&id=52d34756&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52d34756",
  null
  
)

export default component.exports