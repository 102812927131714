<template>
  <v-card class="mb-5 mt-2 pathogen-pipelines-pathogen-chart">
    <v-flex blue-grey darken-3 white--text>
      <v-card-title primary-title class="py-2">
        <v-icon large left class="white--text">
          {{ data.section.icon }}
        </v-icon>
        <span class="title font-weight-light my-3">
          {{ data.section.text }} ({{ data.section.count }})</span
        >
        <v-spacer></v-spacer>
        <v-text-field
          color="white"
          append-icon="search"
          dark
          placeholder="Search for Organism"
          class="org-search"
          v-model="search"
        ></v-text-field>
      </v-card-title>
    </v-flex>
    <v-expansion-panel expand v-model="expand">
      <v-expansion-panel-content
        v-for="header in getHeaders()"
        :key="header.value"
      >
        <template v-slot:header>
          <div class="font-weight-bold">{{ header.text }}</div>
        </template>
        <v-card>
          <v-card-text>
            <div v-if="header.all" class="ml-4">
              All <span class="font-weight-bold">{{ data.section.count }} {{ data.section.text }}</span> support {{ header.text }}
            </div>
            <div v-else-if="header.value === 'other'" class="ml-4">
              <ul>
                <li
                  v-for="[otherName, organisms] in getOther(header.value)"
                >
                  {{ otherName }}:
                  <v-chip
                    label
                    v-for="org in organisms"
                    :key="org"
                    :color="searchMatch(org) ? 'blue-grey darken-3' : null"
                    :text-color="searchMatch(org) ? 'white' : null"
                  >
                    {{ org }}
                  </v-chip>
                </li>
              </ul>
            </div>
            <div v-else class="ml-4">
              <div>
                The following {{ data.section.text }} support {{ header.text }}:
              </div>
              <v-chip
                label
                v-for="org in getSupportedOrganisms(header.value)"
                :key="org"
                :color="searchMatch(org) ? 'blue-grey darken-3' : null"
                :text-color="searchMatch(org) ? 'white' : null"
              >
                {{ org }}
              </v-chip>
            </div>
          </v-card-text>
        </v-card>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-card>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { Watch } from "vue-property-decorator";

const ComponentProps = Vue.extend({
  props: {
    data: {
      type: Object,
    },
  },
});

@Component
export default class PathogenChart extends ComponentProps {
  private expand: boolean[] = []
  private search: string = ""

  private getHeaders() {
    let headers = this.data.headers.filter(h => !h.other)
    if (this.data.headers.some(h => h.other)) {
      headers.push({text: "Other", value: "other", all: false, other: true})
    }
    return headers
  }

  private mounted() {
    this.expand = this.getHeaders().map(h => h.all)

    // run some validation of the data
    const allFeatures = new Set(this.data.data.flatMap(d => d.features))

    // we exclude headers applying to all, as no organism should reference that specifically
    const knownHeaders = new Set(this.data.headers.filter(h => !h.all).map(h => h.value))
    const unrecognizedFeatures = new Set([...allFeatures].filter(f => !knownHeaders.has(f)))
    if (unrecognizedFeatures.size > 0) {
      throw new Error(`Unrecognized features: ${unrecognizedFeatures}`)
    }

    const headersWithoutOrganisms = new Set([...knownHeaders].filter(h => !allFeatures.has(h)))
    if (headersWithoutOrganisms.size > 0) {
      throw new Error(`Empty header: ${headersWithoutOrganisms}`)
    }
  }

  private searchMatch(organism: string): boolean {
    if (this.search.length === 0) {
      return false
    }
    return organism.toLowerCase().indexOf(this.search.trim().toLowerCase()) !== -1
  }

  @Watch("search")
  private async searchChanged(search) {
    this.expand = this.getHeaders().map(h => {
      if (h.all) {
        return true
      }

      if (!h.other) {
        return this.getSupportedOrganisms(h.value).some(o => this.searchMatch(o))
      }

      // for other, check if any orgs under any other category match
      return this.data.headers.filter(h => h.other).some(h => this.getSupportedOrganisms(h.value).some(o => this.searchMatch(o)))
    })
  }

  private getSupportedOrganisms(headerValue: string): string[] {
    return this.data.data.filter(d => d.features.includes(headerValue)).map(d => d.name).sort()
  }

  private getOther(headerValue: string) {
    return this.data.headers.filter(h => h.other).map(h => [h.text, this.data.data.filter(d => d.features.includes(h.value)).map(d => d.name)])
  }
};
</script>

<style scoped>
/* not sure why scoped styles don't work properly here... */
:global(.pathogen-pipelines-pathogen-chart .theme--dark.v-input.org-search input::placeholder) {
  color: white;
  opacity: 0.8;
}
</style>
