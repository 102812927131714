<template>
  <v-app class="white google-font">
    <v-container fluid class="grey lighten-4 pa-0">
      <v-layout column>
        <v-flex class="blue darken-2 py-2">
          <v-layout justify-center>
            <a v-on:click="redirectToSignUp()" class="white--text subheading banner-text my-1">
              <v-layout>
                <b>Free Trial<span class="mx-2">|</span></b>Try out BugSeq on your own data for free 
                <v-icon dark class="ml-1">keyboard_double_arrow_right</v-icon>
              </v-layout>
            </a>
          </v-layout>
        </v-flex>
        <v-flex>
          <v-img
            :src="HeroImg"
            gradient="to right, #0D47A1, rgba(0, 0, 0, 0)"
            max-height="350px"
            position="center 70%"
          >
            <v-layout fill-height justify-center>
              <v-flex class="content-max-width">
                <v-layout fill-height align-center>
                  <v-flex xs12 md5 class="mx-5 blue-grey darken-4 white--text hero-text-box">
                    <div class="display-1 pa-4">Analyze with BugSeq</div>
                    <div class="pl-4">Making sequencing accessible to all by reimagining reporting and analysis.</div>
                    <v-btn class="ma-4 text-capitalize" v-on:click="redirectToSignUp()">Get Started on BugSeq</v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-img>
        </v-flex>
        <v-flex class="px-5 py-4 mb-4">
          <v-layout justify-center>
            <v-flex class="content-max-width">
              <v-layout>
                <v-flex
                  xs12
                  sm6
                  md4
                  class="card-border-left-black px-3"
                  v-for="card in cardsSection1"
                  :key="card.title"
                >
                  <div class="my-3 ml-2">
                    <div class="title mb-3">{{card.title}}</div>
                    <div>{{card.text}}</div>
                  </div>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex class="px-5 py-4 white">
          <v-layout justify-center>
            <v-flex class="content-max-width">
              <v-layout align-center class="my-5">
                <v-flex hidden-xs-only sm4 class="px-3">
                  <v-img
                    :src="ComputerReportImg"
                    class="stock-image border-radius"
                  ></v-img>
                </v-flex>
                <v-flex sm8 class="px-3">
                  <div class="headline">
                    <v-icon large class="open-quote">format_quote</v-icon>
                      [BugSeq] has been validated for clinical samples for detection of SARS-CoV-2 and novel viruses present in metagenomic data
                    <v-icon large>format_quote</v-icon>
                  </div>
                  <div class="my-3 headline">
                    - <a href="https://doi.org/10.1128/cmr.00119-22" target="_blank">Gauthier et al</a>
                  </div>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex class="blue-grey darken-4 white--text px-5 pb-4">
          <v-layout justify-center>
            <v-flex xs12 class="content-max-width">
              <v-layout align-stretch wrap class="my-4">
                <v-flex
                  xs12
                  sm6
                  md3
                  v-for="section in sections"
                  :key="section.anchor"
                  class="pa-3"
                >
                  <v-layout column class="card-section-2 border-radius pa-4" align-center fill-height>
                    <v-flex shrink>
                      <v-img
                        :src="section.image"
                        width="80px"
                        class="dark-card-image"
                      ></v-img>
                    </v-flex>
                    <v-flex class="font-weight-bold mt-4 mb-2 text-xs-center" shrink>
                      {{section.title}}
                    </v-flex>
                    <v-flex grow class="text-xs-center">
                      {{section.shortText}}
                    </v-flex>
                    <v-flex shrink>
                      <v-btn
                        flat
                        outline
                        class="white--text text-capitalize mx-0 mt-4"
                        :href="`#${section.anchor}`"
                      >
                        Learn More
                        <v-icon dark class="ml-1">keyboard_double_arrow_right</v-icon>
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex
          v-for="(section, idx) in sections"
          :key="section.anchor"
          class="px-5 py-4 mb-4"
          :class="{white: idx % 2 === 1}"
        >
          <v-layout justify-center>
            <v-flex class="content-max-width">
              <div class="py-5 ml-2" :id="section.anchor">
                <div class="title mb-3">{{section.title}}</div>
                <div>{{section.text}}</div>
                <div v-if="section.anchor == 'databases'" class="mt-4">
                  <v-layout class="mt-3" row wrap align-center>
                    <v-flex xs12 class="grey lighten-4 pa-4 border-radius">
                      <v-layout row wrap>
                        <v-flex xs4 class="database-comparison-header-row"></v-flex>
                        <v-flex xs4 class="database-comparison-header-row text-xs-center font-weight-bold pt-4 pb-4">RefSeq</v-flex>
                        <v-flex xs4 class="database-comparison-header-row text-xs-center font-weight-bold pt-4 pb-4 white border-radius-top">BugRef</v-flex>
                        <v-flex
                          xs4
                          v-for="(col, idx) in databaseComparisonTable.data.flatMap(r => [r.name, r.refseq, r.bugref])"
                          :key="idx"
                          :class="{ 'font-weight-bold': idx % 3 === 0, 'px-4': idx % 3 !== 0, white: idx % 3 === 2, 'border-radius-bottom': idx === databaseComparisonTable.data.length * 3 - 1 }"
                          class="py-3"
                        >{{col}}</v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </div>
                <div v-else-if="section.anchor == 'taxonomic-classification'" class="mt-4">
                  <v-layout row>
                    <v-flex
                      xs4
                      class="pa-3"
                      v-for="publication in taxonomicClassificationPublications"
                      :key="publication.href"
                    >
                      <div class="white border-radius pa-2">
                        <a
                          :href="publication.href"
                          target="_blank"
                        >
                          <v-img
                            :src="publication.image"
                          ></v-img>
                        </a>
                      </div>
                    </v-flex>
                  </v-layout>
                </div>
                <div v-else-if="section.anchor == 'amr'" class="mt-4">
                  <div class="my-2">
                    Predicted resistance for all bacteria:
                  </div>
                  <ul class="pb-5">
                    <li>Predict phenotype with advanced machine learning models</li>
                    <li>Detect genes, mutations and complex genetic interactions</li>
                    <li>Species-specific AMR models automatically applied</li>
                    <li>&lt;6000 protein sequences in BugSeq’s curated database</li>
                  </ul>
                  <v-layout row wrap class="white border-radius pa-5" align-center>
                    <v-flex xs12 md6 class="pr-5">
                      <div class="headline">
                        <v-icon large class="open-quote">format_quote</v-icon>
                          BugSeq's predicted phenotype matched the observed phenotype for all isolates
                        <v-icon large>format_quote</v-icon>
                      </div>
                      <div class="mt-3 headline">
                        - <a href="https://doi.org/10.1093/jac/dkad346" target="_blank">Ritchie et al</a>
                      </div>
                    </v-flex>
                    <v-flex hidden-sm-and-down md6 class="border-radius pa-2">
                      <v-card class="pa-2">
                        <v-img
                          :src="ReportSampleAMRImg"
                        ></v-img>
                      </v-card>
                    </v-flex>
                  </v-layout>
                </div>
                <div v-else-if="section.anchor == 'outbreak-analysis'" class="mt-4">
                  <v-layout row wrap class="white border-radius pa-5" align-center>
                    <v-flex hidden-xs-only sm2>
                      <v-img
                        :src="ReportSampleOutbreakAnalysisImg"
                      ></v-img>
                    </v-flex>
                    <v-flex xs12 sm10 class="pl-5">
                      Perform surveillance and track transmission in real time. BugSeq offers exceptional accuracy and an exclusive species-agnostic analysis.
                    </v-flex>
                  </v-layout>
                </div>
                <div v-else-if="section.anchor == 'plasmid-detection'" class="mt-4">
                  <v-layout row wrap class="white border-radius pa-5" align-center>
                    <v-flex hidden-sm-and-down md6>
                      <v-card class="pa-2">
                        <v-img
                          :src="ReportSamplePlasmidDetectionImg"
                        ></v-img>
                      </v-card>
                    </v-flex>
                    <v-flex xs12 md6 class="pl-5">
                      <div class="headline">
                        <v-icon large class="open-quote">format_quote</v-icon>
                        Functional annotation of the assembled plasmid genomes using BugSeq revealed that KPC-2 and KPC-14 were both located on IncN plasmids
                        <v-icon large>format_quote</v-icon>
                      </div>
                      <div class="mt-3 headline">
                        - <a href="https://doi.org/10.21203/rs.3.rs-3620416/v1" target="_blank">Urban et al</a>
                      </div>
                    </v-flex>
                  </v-layout>
                </div>
                <div v-else-if="section.anchor == 'quality-control'" class="mt-4">
                  <v-layout row wrap class="white border-radius pa-5" align-center>
                    <v-flex xs12 md6 class="pr-5">
                      <div class="headline">
                        <v-icon large class="open-quote">format_quote</v-icon>
                        We validated the BugSeq as a potentially powerful clinical bioinformatics platform and workflow, including quality control, data visualization, taxonomic classification, consensus sequence generation, data aggregation, and results reporting.
                        <v-icon large>format_quote</v-icon>
                      </div>
                      <div class="mt-3 headline">
                        - <a href="https://doi.org/10.1371/journal.pone.0259712" target="_blank">Gauthier et al</a>
                      </div>
                    </v-flex>
                    <v-flex md6 hidden-sm-and-down>
                      <v-card class="pa-2">
                        <v-img
                          :src="ReportSampleQualityControlImg"
                        ></v-img>
                      </v-card>
                    </v-flex>
                  </v-layout>
                </div>
                <div v-else-if="section.anchor == 'subtyping'" class="mt-4">
                  <v-btn
                    color="primary"
                    class="text-capitalize mx-0"
                    href="/pathogen-pipelines"
                  >See all supported analyses</v-btn>
                </div>
                <div v-else-if="section.anchor == 'pathogen-identification'" class="mt-4">
                  <v-layout row wrap align-center>
                    <v-flex xs12 md6>
                      <v-card class="pa-2">
                        <v-img
                          :src="ReportSamplePathogenIdentificationImg"
                        ></v-img>
                      </v-card>
                    </v-flex>
                    <v-flex xs12 md6 class="pl-5 py-5">
                      Leverage smart algorithms to identify important organisms. BugSeq's analysis factors in:
                      <ul>
                        <li>Sample type</li>
                        <li>Organism abundance</li>
                        <li>Molecule type (DNA/RNA)</li>
                        <li>Common sequencing contaminants</li>
                        <li>Control sequences</li>
                      </ul>
                    </v-flex>
                  </v-layout>
                </div>
              </div>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex class="px-5 py-4">
          <v-layout justify-center>
            <v-flex class="content-max-width py-4" xs12 md8>
              <div class="headline">
                <v-icon large class="open-quote">format_quote</v-icon>
                  As diagnostic microbiology laboratories adopt NGS for bacterial isolate and metagenomic sequencing, automated tools to detect pathogens and leverage the existing body of pathogen-specific bioinformatic analyses will enable a faster, easier transition to NGS. We anticipate that [BugSeq] will be broadly useful for the detection and characterization of organisms from diverse samples.
                <v-icon large>format_quote</v-icon>
              </div>
              <div class="mt-3 headline">
                - <a href="https://doi.org/10.1038/s42003-022-03114-4" target="_blank">Chandrakumar et al</a>
              </div>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex class="blue-grey darken-4 white--text px-5 py-4">
          <v-layout justify-center>
            <v-flex class="content-max-width">
              <v-layout align-center>
                <v-flex xs12>
                  <div class="font-weight-bold">Get Started</div>
                  <div class="mt-2">Ready to see BugSeq in action? Submit some data to see how it works</div>
                  <v-btn class="my-4 mx-0 text-capitalize" v-on:click="redirectToSignUp()">Get Started on BugSeq</v-btn>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>

<script>
import HeroImg from "@/assets/img/analysis/lab-computer-alt.jpg";
import ReportSampleAMRImg from "@/assets/img/analysis/report-sample-amr.png";
import ReportSamplePlasmidDetectionImg from "@/assets/img/analysis/report-sample-plasmid-detection.png";
import ReportSampleQualityControlImg from "@/assets/img/analysis/report-sample-quality-control.png";
import ReportSampleOutbreakAnalysisImg from "@/assets/img/analysis/report-sample-outbreak-analysis.png";
import ComputerReportImg from "@/assets/img/analysis/computer-report.jpg";
import TaxonomicClassificationImg from "@/assets/img/analysis/metagenomics.png";
import DatabasesImg from "@/assets/img/analysis/databases.png";
import AMRImg from "@/assets/img/analysis/amr.png";
import OutbreakAnalysisImg from "@/assets/img/analysis/outbreak-analysis.png";
import PlasmidDetectionImg from "@/assets/img/analysis/plasmid-detection.png";
import QualityControlImg from "@/assets/img/analysis/quality-control.png";
import ReportSamplePathogenIdentificationImg from "@/assets/img/analysis/report-sample-pathogen-identification.png";
import SubtypingImg from "@/assets/img/analysis/subtyping.png";
import PathogenIdentificationImg from "@/assets/img/analysis/pathogen-identification.png";
import TaxClassificationPubPortikBrownImg from "@/assets/img/analysis/tax-classification-pub-portik-brown.png"
import TaxClassificationPubBugSplitImg from "@/assets/img/analysis/tax-classification-pub-bugsplit.png"
import TaxClassificationPubBugseqHighlyAccurateImg from "@/assets/img/analysis/tax-classification-pub-bugseq-highly-accurate.png"
import { redirectToSignUp } from "@bugseq-site/www/src/utils";

export default {
  metaInfo: {
    title: "Analysis",
  },
  methods: {
    redirectToSignUp,
  },
  data() {
    return {
      HeroImg,
      ReportSampleAMRImg,
      ReportSamplePlasmidDetectionImg,
      ReportSampleQualityControlImg,
      ReportSampleOutbreakAnalysisImg,
      ReportSamplePathogenIdentificationImg,
      OutbreakAnalysisImg,
      ComputerReportImg,
      TaxClassificationPubPortikBrownImg,
      TaxClassificationPubBugSplitImg,
      TaxClassificationPubBugseqHighlyAccurateImg,
      cardsSection1: [
        {
          title: "Leading Accuracy",
          text: "Results you can trust, with validated accuracy across clinical, public health, defense and research.",
        },
        {
          title: "Completely Automated",
          text: "No infrastructure required. It's as easy as submitting data. Results available within hours.",
        },
        {
          title: "Fully Customizable",
          text: "Reports customized to meet data reporting and format requirements of your lab.",
        },
      ],
      sections: [
        {
          anchor: "taxonomic-classification",
          title: "Taxonomic Classification",
          image: TaxonomicClassificationImg,
          shortText: "Identify pathogens with confidence.",
          text: "Cutting edge algorithms to identify organisms, from bacterial isolates to polymicrobial samples. Backed by literature.",
        },
        {
          anchor: "databases",
          title: "Curated Database",
          image: DatabasesImg,
          shortText: "High quality sequences, without contamination.",
          text: "Databases drive the accuracy of results, ensuring findings are up-to-date and reducing error.",
        },
        {
          anchor: "amr",
          title: "Antimicrobial Resistance",
          image: AMRImg,
          shortText: "Predicted resistance for all bacteria.",
          text: ""
        },
        {
          anchor: "outbreak-analysis",
          title: "Outbreak Analysis",
          image: OutbreakAnalysisImg,
          shortText: "Perform surveillance and track transmission in real time.",
          text: "",
        },
        {
          anchor: "plasmid-detection",
          title: "Plasmid Detection",
          image: PlasmidDetectionImg, 
          shortText: "Track the spread of plasmids.",
          text: "Characterize all plasmids in the sample. Track antimicrobial resistance spread by plasmids.",
        },
        {
          anchor: "quality-control",
          title: "Quality Control",
          image: QualityControlImg,
          shortText: "Gain confidence by controlling data quality.",
          text: "Assess and control data quality to make confident decisions.",
        },
        {
          anchor: "subtyping",
          title: "Subtyping",
          image: SubtypingImg,
          shortText: "Characterize organisms beyond the species level.",
          text: "Strain type organisms from culture or metagenomics for comparison with legacy techniques.",
        },
        {
          anchor: "pathogen-identification",
          title: "Pathogen Identification",
          image: PathogenIdentificationImg,
          shortText: "Leverage smart algorithms to identify important organisms.",
          text: "",
        },
      ],
      // data is structured this way because it is compat with v-data-table
      databaseComparisonTable: {
        "headers": [
          {},
          {
            "text": "RefSeq",
            "value": "refseq",
            "align": "center",
          },
          {
            "text": "BugRef",
            "value": "bugref",
            "align": "center"
          },
        ],
        "data": [
          {"name": "Taxonomic misannotation", "refseq": "1% of sequences", "bugref": "<0.0001% of sequences"},
          {"name": "Sequence contamination", "refseq": ">100,000 sequences", "bugref": "<0.0001% of sequences"},
          {"name": "Viral diversity", "refseq": "10,000 viral sequences (1 per virus)", "bugref": ">100,000 viral sequences for optimal sensitivity"},
          {"name": "Taxonomic overrepresentation", "refseq": ">10,000 E. coli genomes", "bugref": "Genome deduplication for faster analysis"},
          {"name": "Plasmid host", "refseq": "Assigned to the observed host, leading to erroneous classification", "bugref": "Assigned to predicted host, correcting for horizontal transmission"},
        ],
      },
      taxonomicClassificationPublications: [
        {href: "https://doi.org/10.1186/s12859-022-05103-0", image: TaxClassificationPubPortikBrownImg},
        {href: "https://doi.org/10.1038/s42003-022-03114-4", image: TaxClassificationPubBugSplitImg},
        {href: "https://doi.org/10.1186/s12859-021-04089-5", image: TaxClassificationPubBugseqHighlyAccurateImg},
      ],
    }
  },
};
</script>

<style scoped>
.banner-text {
  text-decoration: none;
}

.blue-grey.darken-4.hero-text-box {
  opacity: 0.85;
  border: 1px solid white;
  border-color: white !important;
}

.content-max-width {
  max-width: 1200px;
}

.border-radius {
  border-radius: 5px;
}

.border-radius-top {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.border-radius-bottom {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.card-border-left-black {
  border-left: 4px solid black;
}

.stock-image {
  filter: grayscale(100%) contrast(100%);
}

.dark-card-image {
  filter: invert(100%);
}

.card-section-2 {
  border: 1px solid white;
  border-radius: 5px;
}

.open-quote {
  transform: rotateY(180deg);
}

.database-comparison-header-row {
  border-bottom: 2px solid #607D8B;
  border-color: #607D8B !important;
}
</style>
