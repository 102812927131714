import Vuex, { Store } from 'vuex'
import { RootState } from './state'
import { Client } from '@bugseq-site/shared/src/lib/api/client'
import { paths } from '@bugseq-site/www/src/lib/api/api'
import { createApiModule } from './modules/api'
import { notifications } from './modules/notifications'

export const createStore = (client: Client<paths>): Store<RootState> => new Vuex.Store<RootState>({
  modules: {
    api: createApiModule(client),
    notifications
  }
})
