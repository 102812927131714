<template>
  <v-app class="white google-font">
    <Banner />

    <v-container fluid class="my-4">
      <v-layout wrap align-center justify-center row fill-height>
        <v-flex xs12 md10>
          <HomeStartScreen />
        </v-flex>
      </v-layout>
    </v-container>

    <v-container fluid class="grey lighten-4">
      <v-layout wrap align-center justify-center row fill-height>
        <v-flex xs12 md10>
          <GetStarted />
        </v-flex>
      </v-layout>
    </v-container>

    <v-container id="whybugseq" fluid class="grey lighten-4">
      <v-layout wrap align-center justify-center row fill-height>
        <v-flex xs12 md10>
          <WhyBugSeq />
        </v-flex>
        <v-flex xs12 md10>
          <SequencersAndProducts />
        </v-flex>
      </v-layout>
    </v-container>

    <v-container fluid class="grey lighten-4">
      <v-layout wrap align-center justify-center row fill-height>
        <v-flex xs12 md10>
          <ExperimentalDesigns />
        </v-flex>
      </v-layout>
    </v-container>

    <v-container id="about" fluid class="blue-grey darken-3 white--text">
      <v-layout wrap align-center justify-center row fill-height>
        <v-flex xs12 md10>
          <HowItWorks />
        </v-flex>
      </v-layout>
    </v-container>

    <v-container fluid class="grey lighten-4">
      <v-layout wrap align-center justify-center row fill-height>
        <v-flex xs12 md10>
          <Applications />
        </v-flex>
      </v-layout>
    </v-container>

    <v-container fluid class="grey lighten-4">
      <v-layout wrap align-center justify-center row fill-height>
        <v-flex xs12 md10>
          <Partners />
        </v-flex>
      </v-layout>
    </v-container>

    <v-container fluid class="blue-grey darken-3 white--text">
      <v-layout wrap align-center justify-center row fill-height>
        <v-flex xs12 md10 class="py-3">
          <Register />
        </v-flex>
      </v-layout>
    </v-container>

    <v-container id="contact" fluid class="grey lighten-4">
      <v-layout wrap align-center justify-center row fill-height>
        <v-flex xs12 md10>
          <Contact />
        </v-flex>
      </v-layout>
    </v-container>

    <CookieLaw />
  </v-app>
</template>

<script>
import Applications from "@bugseq-site/www/src/components/home/Applications";
import HomeStartScreen from "@bugseq-site/www/src/components/home/HomeStartScreen";
import HowItWorks from "@bugseq-site/www/src/components/home/HowItWorks";
import Contact from "@bugseq-site/www/src/components/home/Contact";
import CookieLaw from "@bugseq-site/www/src/components/CookieLaw";
import Banner from "@bugseq-site/www/src/components/home/Banner";
import GetStarted from "@bugseq-site/www/src/components/home/GetStarted";
import SequencersAndProducts from "@bugseq-site/www/src/components/home/SequencersAndProducts";
import ExperimentalDesigns from "@bugseq-site/www/src/components/home/ExperimentalDesigns";
import Partners from "@bugseq-site/www/src/components/home/Partners";
import WhyBugSeq from "@bugseq-site/www/src/components/home/WhyBugSeq";
import Register from "@bugseq-site/www/src/components/home/Register";

export default {
  metaInfo: {
    title: "BugSeq",
    // null is not working, pending update of vue-meta: https://github.com/nuxt/vue-meta/issues/527
    titleTemplate: "",
  },
  components: {
    Applications,
    Banner,
    Contact,
    CookieLaw,
    ExperimentalDesigns,
    SequencersAndProducts,
    GetStarted,
    Partners,
    HomeStartScreen,
    Register,
    HowItWorks,
    WhyBugSeq,
  },
  data() {
    return {
      refreshing: false,
      registration: null,
      timeout: 6000,
    };
  },
};
</script>
