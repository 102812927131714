<template>
  <v-app>
    <v-container fluid class="my-4 google-font">
      <v-layout align-center justify-center row>
        <v-flex xs12 xl8>
          <v-card>
            <HeroQuote />
            <v-layout align-center column class="mt-3">
              <v-flex>
                <h1 class="mt-5 mb-3 text-xs-center">Free Trial</h1>
              </v-flex>
              <v-flex>
                If you are ready to start analyzing data today, get started with 3 free trials. No credit card required.
              </v-flex>
              <v-btn
                color="primary"
                class="text-capitalize mt-4 pa-4"
                @click="redirectToSignUp"
              >Sign Up for 3 Free Trials</v-btn>
            </v-layout>
            <v-layout align-center class="mt-3">
              <v-flex>
                <h1 class="mt-5 mb-3 text-xs-center">Analysis Solutions</h1>
              </v-flex>
            </v-layout>
            <PricingTiers />
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import HeroQuote from "@bugseq-site/www/src/components/pricing/HeroQuote.vue";
import PricingTiers from "@bugseq-site/www/src/components/pricing/PricingTiers.vue";
import { redirectToSignUp } from "@bugseq-site/www/src/utils";

@Component({
  metaInfo() {
    return {
      title: "Pricing",
    };
  },
  components: {
    HeroQuote,
    PricingTiers,
  },
  methods: {
    redirectToSignUp,
  },
})
export default class PricingPage extends Vue {}
</script>
