<template>
  <v-app>
    <v-container fluid class="my-4">
      <v-layout wrap align-start justify-center row fill-height>
        <v-flex xs12 md10 lg6 class="py-2 card">
          <v-card>
            <v-card-title primary-title class="px-5 pt-5">
              <div class="subheading secondary--text my-3">
                July 11, 2024
              </div>
              <h3 class="display-1">
                BugSeq announces second BARDA DRIVe award to develop AI-driven bioinformatic reporting for agnostic diagnostic sequencing assays
              </h3>
            </v-card-title>
            <v-card-text class="px-5 pt-4">
              <p>
                <a href="https://bugseq.com" target="_blank">BugSeq</a>, an innovative biotech company that provides a user-friendly and rapid bioinformatics platform for clinical and public health laboratories, announces it has entered a public-private partnership with the <a href="https://www.medicalcountermeasures.gov/BARDA" target="_blank">Biomedical Advanced Research and Development Authority</a>'s (BARDA) Division of Research, Innovation, and Ventures (DRIVe) to develop an artificial intelligence (AI)-powered reporting tool for clinical metagenomics data. BARDA is part of the Administration for Strategic Preparedness and Response (ASPR) within the U.S. Department of Health and Human Services.
              </p>
              <p>
                Metagenomic sequencing (mNGS) carries the potential to enable pathogen-agnostic diagnostics. Most of today's clinical diagnostic tools are designed to detect the presence of specific and known pathogens, which can lead to delayed diagnosis. These targeted assays – like the PCR and antigen tests that were widely used during the COVID-19 pandemic – are critical for infectious disease diagnostics. Yet, targeted diagnostic tests are reactive by design and are typically not available until weeks or months into an outbreak. This can be costly for patient treatment if multiple tests are needed to establish a diagnosis. In the presence of an emerging pathogen of epidemic or pandemic potential, widespread access to an agnostic diagnostic test coupled with regulatory-compliant bioinformatics would enable an early and effective response to the public health threat.
              </p>
              <p>
                There are currently no FDA-approved mNGS-based diagnostic assays. While many advancements have been made to improve diagnostic accuracy through improved sample preparation and sequencing techniques, interpretive and regulatory-compliant bioinformatics remains one of the final barriers to widespread adoption and clinical impact of mNGS. Specifically, the high-dimensionality and sensitivity of next-generation sequencing data makes establishing rules-based thresholds for reporting pathogens from clinical specimens difficult.
              </p>
              <p>
                BugSeq is partnering with BARDA to harness machine learning tools that capture mNGS data features to provide actionable reporting of pathogens present in clinical metagenomic data. Under this contract , BugSeq will develop and evaluate this tool with a focus on regulatory compliance, including deployment in real-world settings to establish robust performance estimates. Through this work, BugSeq will enable rapid mNGS data interpretation, improve accuracy over existing bioinformatic approaches, and drive mNGS technology towards routine diagnostic use in hospital settings.
              </p>
              <p>
                Clinical metagenomics could revolutionize infectious disease diagnostics and improve patient care by reducing the time to accurate diagnosis and treatment. Furthermore, in the presence of another pathogen of pandemic potential, public health and response communities need comprehensive diagnostic tests to quickly detect and respond to the next emerging pathogen. BugSeq is improving the performance, usability, and interpretability of these NGS-based agnostic diagnostics to reduce the burden of infectious diseases. 
              </p>
              <p>
                This project has been supported in whole or in part with federal funds from the U.S. Department of Health and Human Services; Administration for Strategic Preparedness and Response ; Biomedical Advanced Research and Development Authority (BARDA), under contract 75A50124C00024.
              </p>
              <p>
                <div class="font-weight-bold mb-2">About BugSeq</div>
                BugSeq is leveraging advanced sequencing technologies to revolutionize infectious disease testing and pathogen surveillance. BugSeq's analysis suite delivers unparalleled data analytics and industry-leading expertise to convert sequencing data into actionable results. By profiling tens of thousands of microorganisms and markers of antimicrobial resistance from any specimen, BugSeq enables broad adoption of sequencing to improve health.
              </p>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>

<script>
export default {
  metaInfo: {
    title:
      "BugSeq announces second BARDA DRIVe award to develop AI-driven bioinformatic reporting for agnostic diagnostic sequencing assays",
    titleTemplate: "",
  },
};
</script>

<style scoped>
p {
  margin-bottom: 36px;
}
</style>
