<template>
  <v-layout wrap align-center justify-center row fill-height>
    <v-layout justify-space-around wrap xs12>
      <v-flex v-for="(step, idx) in steps" :key="step.text" xs12 sm6 lg3>
        <v-layout wrap fill-height class="py-2">
          <v-flex xs12 sm10>
            <v-card class="icon-text">
              <v-layout pt-4 px-4 column align-center>
                <v-icon medium :color="step.iconColor">{{ step.icon }}</v-icon>
                <p class="step-text" v-html="step.text"></p>
              </v-layout>
            </v-card>
          </v-flex>
          <v-flex
            xs12
            sm2
            class="arrow-container"
            v-if="idx !== steps.length - 1"
          >
            <v-layout
              class="hidden-xs-only"
              align-center
              justify-center
              fill-height
            >
              <v-icon medium dark>east</v-icon>
            </v-layout>
            <v-icon class="mt-3 hidden-sm-and-up" medium dark>south</v-icon>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-layout>
</template>

<script>
export default {
  data() {
    return {
      steps: [
        {
          icon: "fa-upload",
          iconColor: "blue darken-2",
          text: "Sequence your sample and upload raw data",
        },
        {
          icon: "fa-dna",
          iconColor: "orange darken-4",
          text: "BugSeq intelligently determines experimental design",
        },
        {
          icon: "fa-microchip",
          iconColor: "teal darken-1",
          text: "BugSeq analyzes your data in the cloud",
        },
        {
          icon: "fa-chart-line",
          iconColor: "purple",
          text:
            'Receive actionable reports. See our <a href="/solutions">solutions</a> for example reports.',
        },
      ],
    };
  },
};
</script>

<style scoped>
.step-text {
  margin-top: 20px;
  text-align: center;
}

.icon-text {
  height: 100%;
}

.arrow-container {
  text-align: center;
}
</style>
