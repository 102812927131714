<template>
  <v-app class="white google-font">
    <v-container fluid>
      <v-layout md6 wrap align-center justify-center row>
        <v-flex xs12 md10 pt-2>
          <p class="home-section-title">Supported analyses</p>
          <p>
            BugSeq provides tailored analyses for various viruses, fungi and
            bacteria. We're constantly building new analyses. Don't see the
            analysis you're looking for? Send us a message at
            <a href="mailto:contact@bugseq.com">contact@bugseq.com</a>.
          </p>
        </v-flex>
        <v-flex xs12 md10>
          <PathogenChart :data="BacteriaData" />
        </v-flex>
        <v-flex xs12 md10>
          <PathogenChart :data="VirusData" />
        </v-flex>
        <v-flex xs12 md10>
          <PathogenChart :data="FungusData" />
        </v-flex>
        <v-flex xs12 md10>
          <PathogenChart :data="ParasiteData" />
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>

<script>
import PathogenChart from "@bugseq-site/www/src/components/pathogen-pipelines/PathogenChart";
import BacteriaData from "@bugseq-site/www/src/assets/data/pathogen-pipelines/bacteria.json"; // Bacterial table comes from available MLST schemes: https://github.com/tseemann/mlst/blob/7c68056a9b5786febcd189d1d611ee9a057d0660/db/scheme_species_map.tab
import VirusData from "@bugseq-site/www/src/assets/data/pathogen-pipelines/virus.json";
import FungusData from "@bugseq-site/www/src/assets/data/pathogen-pipelines/fungus.json";
import ParasiteData from "@bugseq-site/www/src/assets/data/pathogen-pipelines/parasite.json";

export default {
  metaInfo: {
    title: "Supported Analyses",
    meta: [
      {
        vmid: "description",
        name: "description",
        content:
          "BugSeq supports a growing list of pathogen-specific analyses, including AMR, MLST, phylogenetics and more.",
      },
    ],
  },
  components: {
    PathogenChart,
  },
  data() {
    return {
      BacteriaData,
      VirusData,
      FungusData,
      ParasiteData,
    };
  },
};
</script>

<style scoped>
.stock-image {
  filter: invert(100%);
}

.border-radius {
  border-radius: 5px;
}
</style>
