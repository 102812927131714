<template>
  <v-container class="pa-0">
    <b>Proud partners</b>
    <v-layout wrap row align-center justify-space-around>
      <v-flex
        xs5
        sm4
        md3
        v-for="partner in partners"
        align-self-center
        :key="partner.name"
      >
        <a :href="partner.link" target="_blank"
          ><v-layout justify-center>
            <v-img
              :src="partner.image"
              class="partner-image"
            ></v-img> </v-layout
        ></a>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import DRIVeImg from "@/assets/img/partners/DRIVe.png";
import PhacImg from "@/assets/img/partners/phac.png";
import CdcImg from "@/assets/img/partners/cdc.png";
import NrcImg from "@/assets/img/partners/nrc.png";
import OppImg from "@/assets/img/partners/opp.png";
import PhcImg from "@/assets/img/partners/phc.png";
import UbcImg from "@/assets/img/partners/ubc.png";
import VghImg from "@/assets/img/partners/vgh.png";

export default {
  data() {
    return {
      partners: [
        {
          name: "phc",
          image: PhcImg,
          link: "https://www.providencehealthcare.org/",
        },
        { name: "cdc", image: CdcImg, link: "https://www.cdc.gov/" },
        { name: "drive", image: DRIVeImg, link: "https://drive.hhs.gov/" },
        { name: "nrc", image: NrcImg, link: "https://nrc.canada.ca/en" },
        { name: "vgh", image: VghImg, link: "http://www.vch.ca/" },
        { name: "ubc", image: UbcImg, link: "https://www.ubc.ca/" },
        { name: "phac", image: PhacImg, link: "http://www.phac-aspc.gc.ca/" },
        {
          name: "opp",
          image: OppImg,
          link: "https://www.openphilanthropy.org/",
        },
      ],
    };
  },
};
</script>

<style scoped>
.partner-image {
  max-width: 150px;
  margin: 30px;
}
</style>
