<template>
  <v-app>
    <v-container fluid class="my-4">
      <v-layout wrap align-start justify-center row fill-height>
        <v-flex xs12 md10 lg6 class="py-2 card">
          <v-card>
            <v-card-title primary-title class="px-5 pt-5">
              <div class="subheading secondary--text my-3">
                January 11, 2023
              </div>
              <h3 class="display-1">
                BugSeq Joins PacBio Compatible Products, Enables Leading
                Accuracy Infectious Disease Analysis for Long Reads
              </h3>
            </v-card-title>
            <v-card-text class="px-5 pt-4">
              <p>
                BugSeq Bioinformatics, a leading provider of infectious disease
                bioinformatic solutions, announced today that it has joined the
                PacBio Compatible program as a PacBio partner.
              </p>
              <p>
                The announcement comes after a recent, peer-reviewed article
                identified BugSeq as a leading accuracy solution for long read
                metagenomic analysis. The article, published in BMC
                Bioinformatics, found BugSeq provided top performance for both
                PacBio and Oxford Nanopore Sequencing data.
              </p>
              <p>
                "BugSeq’s platform achieved perfect precision and high recall
                with all PacBio HiFi metagenomics datasets in microbial species
                detection," said Trang Dahlen, Infectious Disease and Microbial
                Genomics Director at PacBio. "BugSeq clearly demonstrated the
                power of using long reads for metagenomic applications. I am
                excited to have them be a part of the PacBio Compatible
                program."
              </p>
              <p>
                BugSeq’s platform provides user-friendly, cloud-accelerated
                analysis to profile microbes, antimicrobial resistance markers
                and strain transmission. Combined with the high quality, long
                reads of the PacBio HiFi platform, BugSeq enables recovery of
                complete microbial genomes, strain-level profiling, and
                detection of antimicrobial resistance mutations from metagenomic
                samples.
              </p>
              <p>
                PacBio announced the addition today at the 41st Annual J.P.
                Morgan Healthcare Conference. The live webcasts of the events
                can be accessed at the company’s investors page at
                investor.pacificbiosciences.com. Webcast replays will be
                available for at least 30 days following the presentation.
              </p>
              <p>
                "This partnership builds off of BugSeq's industry-leading
                bioinformatic platform and expertise to enable advanced
                detection and prevention of infectious disease using PacBio
                sequencers", said Sam Chorlton, BugSeq's CEO.
              </p>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>

<script>
export default {
  metaInfo: {
    title:
      "BugSeq Joins PacBio Compatible Products, Enables Leading Accuracy Infectious Disease Analysis for Long Reads",
    titleTemplate: "",
  },
};
</script>

<style scoped>
p {
  margin-bottom: 36px;
}
</style>
