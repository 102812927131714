<template>
  <div class="my-5">
    <h4 class="title">{{ title }}</h4>
    <p class="mt-3">
      {{ description }}
    </p>
    <v-layout row wrap align-top justify-center fill-height>
      <v-flex xs12 sm6>
        <div class="font-weight-bold mb-2">Metagenomic</div>
        <ClickThroughDialogButton
          v-for="link in metagenomicLinks"
          :key="link.text"
          :href="link.href"
          :buttonText="link.text"
          class="ml-0 mr-3"
        ></ClickThroughDialogButton>
      </v-flex>
      <v-flex xs12 sm6>
        <div class="font-weight-bold mb-2">Bacterial Isolate</div>
        <ClickThroughDialogButton
          v-for="link in bacterialIsolateLinks"
          :key="link.text"
          :href="link.href"
          :buttonText="link.text"
          class="ml-0 mr-3"
        ></ClickThroughDialogButton>
      </v-flex>
    </v-layout>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import ClickThroughDialogButton from "@bugseq-site/www/src/components/ClickThroughDialogButton.vue";

const ComponentProps = Vue.extend({
  props: {
    title: String,
    description: String,
    metagenomicLinks: Array,
    bacterialIsolateLinks: Array,
  },
});

@Component({
  components: {
    ClickThroughDialogButton,
  },
})
export default class PacBioSection extends ComponentProps {}
</script>
