import { Module } from 'vuex'
import { ApiState } from './state'
import { Client } from '@bugseq-site/shared/src/lib/api/client'
import { paths } from '@bugseq-site/www/src/lib/api/api'
import { actions } from './actions'

export function createApiModule (client: Client<paths>): Module<ApiState, any> {
  return {
    namespaced: true,
    state: { client },
    actions
  }
}
