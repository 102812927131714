<template>
  <v-app class="white google-font">
    <v-container fluid class="grey lighten-4">
      <v-layout align-center justify-center row>
        <v-flex xs12 md8 xl6>
          <router-view></router-view>
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>

<script>
export default {
  metaInfo: {
    title: "BugSeq",
    titleTemplate: "BugSeq | %s",
  },
};
</script>
