<template>
  <v-card
    class="mb-5 pa-4 card-border"
    :class="publication.pinned ? 'card-border-green' : 'card-border-blue'"
  >
    <v-card-text class="pa-0" v-html="publication.text"> </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

const ComponentProps = Vue.extend({
  props: ["publication"],
});

export default class Publication extends ComponentProps {}
</script>

<style scoped>
.card-border {
  border-left: 8px solid;
}

.card-border-blue {
  border-color: #1976d2; /* blue darken-2 */
}

.card-border-green {
  border-color: #388e3c; /* green darken-2 */
}
</style>
