<template>
  <v-container class="pa-0">
    <v-card class="pa-4 mb-4">
      <p class="font-weight-bold">
        Works with All Sequencing Providers
      </p>
      <v-layout row class="mt-4">
        <v-flex
          xs4
          v-for="entry in logos"
          :key="entry.name"
          align-self-center
        >
          <a
            :href="entry.url"
            target="_blank"
          >
            <v-img
              :src="entry.image"
              max-width="200"
              class="logo"
            ></v-img>
          </a>
        </v-flex>
      </v-layout>
    </v-card>
    <v-card class="pa-4 my-4">
      <p class="font-weight-bold">
        A Product for Every Use-Case
      </p>
      <v-layout row wrap class="pt-2">
        <v-flex
          v-for="product in products"
          :key="product.name"
          shrink
        >
          <v-chip label class="google-font pa-1">{{product.name}}</v-chip>
        </v-flex>
      </v-layout>
      <div class="pt-3 pb-2">Learn More/See Demos:</div>
      <v-layout row wrap>
        <v-btn
          class="text-capitalize"
          color="primary"
          outline
          href="/solutions"
        >Solutions</v-btn>
        <v-btn
          class="text-capitalize"
          color="primary"
          outline
          href="/analysis"
        >Data Analysis</v-btn>
      </v-layout>
    </v-card>
  </v-container>
</template>

<script>
import IlluminaImg from "@/assets/img/home/illumina.png";
import ONTImg from "@/assets/img/home/ont.png";
import PacBioImg from "@/assets/img/demo/pacbio-logo.svg";

export default {
  data() {
    return {
      logos: [
        {name: "Oxford Nanopore Technologies", image: ONTImg, url: "https://nanoporetech.com/"},
        {name: "Illumina", image: IlluminaImg, url: "https://www.illumina.com/"},
        {name: "PacBio", image: PacBioImg, url: "https://www.pacb.com/"},
      ],
      products: [
        {
          name: "Clinical Metagenomics",
        },
        {
          name: "Healthcare-Associated Infections",
        },
        {
          name: "Mycobacterium tuberculosis",
        },
        {
          name: "Isolate Identification",
        },
        {
          name: "Antimicrobial Resistance Prediction",
        },
        {
          name: "Strain Typing",
        },
        {
          name: "Infection Prevention & Control",
        },
        {
          name: "Plasmid Analysis",
        },
        {
          name: "Pandemic Preparedness",
        },
        {
          name: "Research",
        },
        {
          name: "Pathogen Detection",
        },
        {
          name: "Zoonotic Disease",
        },
      ],
    }
  }
};
</script>

<style scoped>
.logo {
  margin-left: auto;
  margin-right: auto;
}
</style>
