<template>
  <v-card class="px-4 pt-4">
    <p class="home-section-title">Support</p>
    <p>
      Fill out the support form below and we will get back to you within 1 business day.
    </p>
    <ContactForm />
  </v-card>
</template>

<script>
import ContactForm from "@bugseq-site/www/src/components/home/ContactForm.vue";

export default {
  metaInfo: {
    title: "Support",
    titleTemplate: "BugSeq | %s",
  },
  components: { ContactForm },
};
</script>
