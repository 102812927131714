<template>
  <div>
    <v-layout align-center column class="blue-grey darken-3 white--text pa-5">
      <v-flex class="mt-3">
        <h1 class="text-xs-center hero-text">Request a Free BugSeq Quote</h1>
      </v-flex>
      <v-flex class="my-3">
        We will contact you to discuss your needs and send tailored pricing
      </v-flex>
    </v-layout>
    <v-form v-if="!submitted">
      <v-layout class="pa-5" row wrap justify-space-between>
        <v-flex xs12 class="mb-3 font-weight-bold">
          Contact Information
        </v-flex>
        <v-flex xs6>
          <v-text-field
            v-model="firstname"
            box
            label="First Name"
            v-validate="'required'"
            data-vv-name="First Name"
            :error-messages="errors.collect('First Name')"
          ></v-text-field>
        </v-flex>
        <v-flex xs5>
          <v-text-field
            v-model="lastname"
            box
            label="Last Name"
            v-validate="'required'"
            data-vv-name="Last Name"
            :error-messages="errors.collect('Last Name')"
          ></v-text-field>
        </v-flex>
        <v-flex xs12>
          <v-text-field
            v-model="email"
            box
            label="Email"
            v-validate="'required|email'"
            data-vv-name="Email"
            :error-messages="errors.collect('Email')"
          ></v-text-field>
        </v-flex>
        <v-flex xs6>
          <v-text-field
            v-model="company"
            box
            label="Institution"
            v-validate="'required'"
            data-vv-name="Institution"
            :error-messages="errors.collect('Institution')"
          ></v-text-field>
        </v-flex>
        <v-flex xs5>
          <v-select
            v-model="labType"
            v-validate="'required'"
            data-vv-name="Type of Lab"
            :error-messages="errors.collect('Type of Lab')"
            box
            label="Type of Lab"
            :items="labTypes"
          ></v-select>
        </v-flex>
        <v-flex xs12 class="mb-3 font-weight-bold">
          Use Case
        </v-flex>
        <v-flex xs12 class="mb-3">
          What kind of samples are you planning to analyze?
        </v-flex>
        <v-flex xs12>
          <v-layout
            row
            wrap
          >
            <v-flex
              xs6
              v-for="(useCaseLabel, useCaseValue) in UseCases"
              :key="useCaseValue"
            >
              <v-checkbox
                v-model="useCases"
                :label="useCaseLabel"
                :value="useCaseValue"
                class="mt-0 pt-0"
                multiple
              ></v-checkbox>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12 class="mb-3 font-weight-bold">
          Volume
        </v-flex>
        <v-flex xs12>
          <v-text-field
            v-model="numSamplesPerYear"
            box
            label="Anticipated Number of Samples per Year"
            v-validate="'required'"
            data-vv-name="Samples per Year"
            :error-messages="errors.collect('Samples per Year')"
          ></v-text-field>
        </v-flex>
        <v-flex xs6>
          <v-checkbox
            v-model="alreadySequencing"
            label="Already Sequencing?"
            class="mt-0 pt-0"
          ></v-checkbox>
        </v-flex>
        <v-flex xs12>
          <v-textarea
            v-model="message"
            data-vv-name="Other"
            :error-messages="errors.collect('Other')"
            box
            label="Anything else we should know?"
          ></v-textarea>
        </v-flex>
        <v-flex xs12>
          <v-btn
            block
            large
            class="blue-grey darken-3 white--text text-capitalize"
            v-on:click="requestQuote()">
            Get a Free Quote
          </v-btn>
        </v-flex>
      </v-layout>
    </v-form>
    <div v-else class="pa-5">
      Thank you for your interest in BugSeq. A member of our team will be in touch within 24 hours.
      <div class="mt-3">
        <v-btn
          class="text-capitalize ml-0"
          color="primary"
          href="/register"
        >
          Register To Submit Trial Data
        </v-btn>
        <v-btn
          class="text-capitalize"
          href="/solutions"
        >
          Explore Solutions
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { Store } from "vuex";
import { components } from '@bugseq-site/www/src/lib/api/api'
import { dispatchSubmitForm } from "@bugseq-site/www/src/store/modules/api/actions";

enum LabType {
  Clinical = "Clinical",
  PublicHealth = "Public Health",
  Academic = "Academic",
  CRO = "Contract Research Organization",
}

// these match backend which matches hubspot
enum UseCases {
  isolates = "Isolates",
  viral = "Viral",
  metagenomics = "Metagenomics",
  sixteen_s_its = "16S/ITS",
  outbreak_analysis = "Outbreak Analysis",
  other = "Other",
}


@Component({
  data() {
    return {
      UseCases,
    };
  },
})
export default class HeroQuote extends Vue {
  private submitted: boolean = false;

  private firstname: string = "";
  private lastname: string = "";
  private email: string = "";
  private company: string = "";
  private labType: LabType | null = null;
  private labTypes: Array<LabType> = [
    LabType.Clinical,
    LabType.PublicHealth,
    LabType.Academic,
    LabType.CRO,
  ]

  private numSamplesPerYear: number | null = null;
  private alreadySequencing: boolean = false;
  private useCases: UseCases[] = [];
  private message: string = "";

  private async requestQuote() {
    if (!(await this.$validator.validateAll())) {
      return
    }

    const body = {
      firstname: this.firstname,
      lastname: this.lastname,
      email: this.email,
      company: this.company,
      type_of_lab: this.labType!,
      use_case: this.useCases,
      number_of_samples_per_year: this.numSamplesPerYear!,
      already_sequencing: this.alreadySequencing,
      message: this.message.length > 0 ? this.message : null,
    }

    await dispatchSubmitForm(this.$store, { body, form: "quote_request" });
    this.submitted = true;
  }
}
</script>

<style scoped>
.hero-text {
  max-width: 800px;
}
</style>
