<template>
  <div class="v-toolbar v-toolbar--fixed theme--light white hidden-sm-and-down">
    <footer>
      <cookie-law theme="mytheme" theme--light white>
        <div slot="message">
          This website uses cookies to ensure you get the best experience. Read
          our
          <a href="https://docs.bugseq.com/legal/privacy-policy" target="_blank"
            >privacy policy</a
          >.
        </div>
      </cookie-law>
    </footer>
  </div>
</template>

<script>
import CookieLaw from "vue-cookie-law";
export default {
  components: { CookieLaw },
};
</script>

<style>
.Cookie--mytheme {
  background: white;
  padding: 0.75em;
  padding-left: 1.25em;
  padding-right: 8em;
  color: rgba(0, 0, 0, 0.87);
  box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.2), 0 0px 20px 0 rgba(0, 0, 0, 0.19);
}
.Cookie--mytheme .Cookie__button {
  background: white;
  padding: 0.4em 3em;
  color: #1a73e8;
  border-radius: 5px;
  border-style: solid;
  border-color: #1a73e8;
  font-size: 1em;
}
.Cookie--mytheme .Cookie__button:hover {
  background: white;
}
</style>
