<template>
  <v-footer height="auto" inset app absolute>
    <v-container fluid>
      <v-layout wrap align-center justify-center row fill-height>
        <v-flex xs12 md10>
          <v-container fluid>
            <v-layout wrap justify-center row>
              <v-layout wrap row pt-1 style="padding-bottom: 70px;">
                <v-flex xs6 md3 class="py-2 card">
                  <p class="google-font mb-0 header font-weight-bold">
                    Platform
                  </p>
                  <div
                    v-for="(item, i) in footerData.Platform"
                    :key="i"
                    class="mt-2"
                  >
                    <a :href="item.Link" class="google-font link">{{
                      item.LinkName
                    }}</a
                    ><br />
                  </div>
                </v-flex>

                <v-flex xs6 md3 class="py-2 card">
                  <p class="google-font mb-0 header font-weight-bold">
                    Resources
                  </p>
                  <div
                    v-for="(item, i) in footerData.Resources"
                    :key="i"
                    class="mt-2"
                  >
                    <a :href="item.Link" class="google-font link">{{
                      item.LinkName
                    }}</a
                    ><br />
                  </div>
                </v-flex>

                <v-flex xs6 md3 class="py-2 card">
                  <p class="google-font mb-0 header font-weight-bold">
                    About Us
                  </p>
                  <div
                    v-for="(item, i) in footerData.AboutUs"
                    :key="i"
                    class="mt-2"
                  >
                    <a :href="item.Link" class="google-font link">{{
                      item.LinkName
                    }}</a
                    ><br />
                  </div>
                </v-flex>

                <v-flex xs6 md3 class="py-2 card">
                  <p class="google-font mb-0 header font-weight-bold">Legal</p>
                  <div
                    v-for="(item, i) in footerData.Legal"
                    :key="i"
                    class="mt-2"
                  >
                    <a :href="item.Link" class="google-font link">{{
                      item.LinkName
                    }}</a
                    ><br />
                  </div>
                </v-flex>
              </v-layout>
            </v-layout>
            <v-divider></v-divider>
            <v-layout align-center justify-space-between row class="mb-0 mt-2">
              <v-flex>
                <img
                  :src="require('@bugseq-site/www/src/assets/img/footer-logo.png')"
                  class="footer-logo"
                />
              </v-flex>
              <v-flex class="text-xs-right">
                ©BugSeq 2024
              </v-flex>
            </v-layout>
          </v-container>
        </v-flex>
      </v-layout>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  data() {
    return {
      footerData: {
        "FooterEndSession": [
          {
            "LinkName": "Privacy",
            "Link": "https://docs.bugseq.com/legal/privacy-policy"
          },
          {
            "LinkName": "Terms of Use",
            "Link": "https://docs.bugseq.com/legal/terms-of-use"
          },
          {
            "LinkName": "Terms of Service",
            "Link": "https://docs.bugseq.com/legal/terms-of-service"
          }
        ],
        "Platform": [
          {
            "LinkName": "BugSeq Platform",
            "Link": "/"
          },
          {
            "LinkName": "Pricing",
            "Link": "/pricing"
          },
          {
            "LinkName": "Microbes",
            "Link": "/pathogen-pipelines"
          }
        ],
        "Resources": [
          {
            "LinkName": "Getting Started",
            "Link": "https://docs.bugseq.com"
          },
          {
            "LinkName": "Docs",
            "Link": "https://docs.bugseq.com"
          },
          {
            "LinkName": "Publications",
            "Link": "/publications"
          }
        ],
        "AboutUs": [
          {
            "LinkName": "News",
            "Link": "/news/2024/07/11/BugSeq-BARDA-AI-bioinformatics"
          },
          {
            "LinkName": "Careers",
            "Link": "/careers"
          }
        ],
        "Legal": [
          {
            "LinkName": "Privacy",
            "Link": "https://docs.bugseq.com/legal/privacy-policy"
          },
          {
            "LinkName": "Terms of Use",
            "Link": "https://docs.bugseq.com/legal/terms-of-use"
          },
          {
            "LinkName": "Terms of Service",
            "Link": "https://docs.bugseq.com/legal/terms-of-service"
          }
        ]
      }
    };
  },
};
</script>

<style scoped>
.link {
  color: #424242; /* grey darken-3 */
  text-decoration: none;
}

.header {
  color: black;
  font-size: 120%;
  font-weight: 300;
}

.card {
  padding: 0% 0% 0% 7%;
}

.footer-logo {
  height: 20px;
}
</style>
