<template>
  <v-container class="pa-0">
    <p class="font-weight-bold">
      Supported experimental designs
    </p>
    <v-layout wrap row justify-space-between class="pt-3">
      <v-flex
        xs12
        sm6
        lg3
        class="px-2 pb-4"
        v-for="entry in cards"
        :key="entry.name"
      >
        <v-card
          v-bind:class="['border-line', 'full-height', entry.borderColorClass]"
        >
          <v-card-title>{{ entry.name }}</v-card-title>
          <v-card-text>{{ entry.description }}</v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      cards: [
        {
          name: "Whole Genome Sequencing",
          borderColorClass: "card-border-blue",
          description:
            "Characterize your pathogen in detail, including antimicrobial resistance, microbial typing and more.",
        },
        {
          name: "Metagenomic Sequencing",
          borderColorClass: "card-border-orange",
          description:
            "Identify any pathogen without targeted testing, and automatically perform detailed analyses on pathogens with enough depth.",
        },
        {
          name: "16S/ITS Sequencing",
          borderColorClass: "card-border-purple",
          description:
            "Rapidly identify bacteria and fungi directly from a sample or culture.",
        },
        {
          name: "Viral Amplicons",
          borderColorClass: "card-border-teal",
          description:
            "Accurately detect viral mutations for resistance testing or phylogenetics.",
        },
      ],
    };
  },
};
</script>

<style scoped>
.full-height {
  height: 100%;
}

.border-line {
  border-left: 8px solid;
}

.card-border-blue {
  border-color: #1976d2; /* blue darken-2 */
}

.card-border-orange {
  border-color: #e65100; /* orange darken-4 */
}

.card-border-teal {
  border-color: #00897b; /* teal darken-1 */
}

.card-border-purple {
  border-color: #9c27b0; /* purple */
}
</style>
